import "regenerator-runtime/runtime";

import { toast } from "react-toastify";
import { handleError } from "../errors";

export const registerUser = async (context, data) => {
    try {
        context.state.loadingBtn = true;
        data.notification_token = context.state.deviceToken
        const result = await context.effects.registerUser(data);

        toast.success("User is registered successfully", {
            position: "top-right",
        });
        context.state.loadingBtn = false;
        return result;
    } catch (err) {
        handleError(err);
        context.state.loadingBtn = false;
    }
};

export const login = async (context, { email, password }) => {
    try {
        context.state.loadingBtn = true;
        const result = await context.effects.login({ email, password });
        console.log("received result", result);
        if(!result){
            throw new Error("No response from Server");
        }
        context.state.accessToken = result?.access?.token;
        context.state.user = result.user?._doc;
        console.log("the user",context.state.user);
        localStorage.setItem('accessToken', result?.access?.token);
        localStorage.setItem('user', JSON.stringify(result.user?._doc));

        context.state.loadingBtn = false;
        return result;
    } catch (err) {
        handleError(err);
        context.state.loadingBtn = false;
    }
};

export const forgotPassword = async (context, { email }) => {
    try {
        await context.effects.forgotPassword(email);

        toast.success("Please check your email for reset password link", {
            position: "top-right",
        });
    } catch (err) {
        handleError(err);
    }
};

export const resetPassword = async (context, { newPassword, token, email }) => {
    try {
        await context.effects.resetPassword({ newPassword, token, email });

        toast.success("Password reset successfully", {
            position: "top-right",
        });
    } catch (err) {

        handleError(err);
    }
};

export const logout = async (context) => {
    try {
        context.state.accessToken = null;
        context.state.user = null;
        localStorage.clear();
    } catch (err) {
        handleError(err);
    }
};
export const verifyEmail = async (context,data) => {
    try {
        await context.effects.verifyEmailToken(data);
    } catch (err) {
        handleError(err);
    }
};
