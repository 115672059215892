import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router";
import { Modal, Button } from 'react-bootstrap';
import { useActions } from "../../../presenter";
import Header from "../../header";
import statesData from '../../../assets/places/governorates.json';
import citiesData from '../../../assets/places/cities.json';
import { codexGenerator } from "./helper";
import '../../../assets/css/createAdSpace.css';
import { AdSpaceValidator } from "../../../validators/adSpace.validator";
// import locationData from "../adSpace/location.json";
import SearchMap from "../../map/searchMap";
import FilePondUpload from "./filePond";

const CreateAdSpace = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [adLocation, setAdLocation] = useState([]);
    const [allSubCategoriesFields, setAllSubCategoriesFields] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedSubCategory, setSelectedSubCategory] = useState("");
    const [adCode, setAdCode] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [uploadedFiles, setUploadedFiles] = useState([]);
    // const [selectedCityId, setSelectedCityId] = useState("");
    const [vendorName, setVendorName] = useState(null);
    const [isCodeDisabled, setIsCodeDisabled] = useState(true);
    const [selectedFields, setSelectedFields] = useState([]);
    const [loadingBtn, setLodingBtn] = useState(false);
    const [stay,setStay] = useState(false);
    const [url, updateUrl] = useState([]);
    const [modalShow, setModalShow] = useState(false);

    const handleClose = () => setModalShow(false);
    const handleShow = () => setModalShow(true);
    const { getCategories, getSubcategoriesFields, getListing, createAd, editAd } = useActions();
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        // getValues,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(AdSpaceValidator)
    });

    const replacementMap = {
        'BillBoard': 'Billboard',
        'DigitalScreen': 'Digital Screens',
        'TransitMedia': 'Transit Media',
        'PlaceBased': 'Place-Based Media',
        'Street': 'Street',
    };

    useEffect(() => {
        // console.log("locationData", locationData);
        fetchCategories();
        getSubcategoriesFields().then((response) => {
            console.log("response", response);
            setAllSubCategoriesFields(response);
        });
    }, []);

    useEffect(() => {
        if (id) {
            getListing(id).then((data) => {
                console.log("data", data);
                if (allSubCategoriesFields && categories.length) handleCategoryChange(data.category);
                setSelectedCity(data.location.city);

                // Use reset function to set all form values at once
                reset(data);

                setValue("width", data.size?.width);
                setValue("height", data.size?.height);
                setValue("city", data.location.city);
                setValue("district", data.location.district);
                setValue("area", data.location.area);
                setValue("landmark", data.location.landmark);
                setValue("geoLocation", data.location.geoLocation);
                if (data.lighting){
                    setValue("lighting", data.lighting ? true : false);
                }
                else{
                    setValue("lighting", false);
                }
            });
        }
    }, [id, reset, setValue, allSubCategoriesFields]);

    const fetchCategories = async () => {
        try {
            const response = await getCategories();
            const orderedCategories = sortCategories(response);
            setCategories(orderedCategories);
        } catch (error) {
            console.error(error);
        }
    };

    const sortCategories = (list)=>{
        // console.log("the list",list);
        const customOrder = ['BillBoard', 'DigitalScreen', 'TransitMedia', 'PlaceBased','Street'];
        list.sort((a, b) => customOrder.indexOf(a.name) - customOrder.indexOf(b.name));
        return list;
    }
    const handleVendorName = (e) =>{
        const vname= e.target.value;
        // console.log("vendor name",e.target.value);
        if(vname.length>2){
            setIsCodeDisabled(false);
        }
        else{
            setIsCodeDisabled(true);
        }
        setVendorName(vname);
    }
    const handleCodeGen = (e) =>{
        e.preventDefault();
        const data = {
            'city':selectedCity,
            'category':selectedCategory,
            'subCategory':selectedSubCategory
        };
        // to do get user name
        const user = vendorName;
        const code = codexGenerator(data,user);
        
        // console.log("generated code",typeof(code));
        setAdCode(code);
        setValue("listing_id",code);
        console.log("generated code",adCode);
    }
    const handleSubCategoryChange = (subCat)=>{
        if(subCat){
            setSelectedSubCategory(subCat)
        }
    }
    const handleCategoryChange = (category) => {
        setSubCategories([]);
        if (!category) {
            // check to see if this is called initially.
            console.log("initial cat");
            setSelectedFields([]);
            return;
        }
        console.log("selected cat",category);
        setSelectedCategory(category);
        setSelectedFields(allSubCategoriesFields[category]);

        // search in categories array
        const categoryFound = categories.find(item => item.name === category);
        setSubCategories(categoryFound.subcategories);
    };
    const handleImg = (links) => {
      console.log("handling ", links);
      setUploadedFiles(links);
      updateUrl(links);
    };
    const onError =  (errors,e) =>{
        console.log("errors in form",errors);
    }
    const onSubmit = async (data,e) => {
        console.log("data", data);
        try{
        setLodingBtn(true);
        if (id) {
            await editAd({
                body: {
                    ...data,
                    main_img_url:url,
                    location: {
                        city: data.city,
                        district: data.district,
                        area: data.area,
                        landmark: data.landmark,
                        geoLocation: adLocation
                    },
                    size: {
                        width: data.width,
                        height: data.height
                    }
                },
                id,
            });
        } else {
            await createAd({
                ...data,
                
                main_img_url:url,
                faces:Number(data.faces),
                user_id: JSON.parse(localStorage.getItem("user"))._id,
                location: {
                    city: data.city,
                    district: data.district,
                    landmark: data.landmark,
                    geoLocation: adLocation
                },
                size: {
                    width: data.width,
                    height: data.height
                }
            });
        }
        setLodingBtn(false);
        if(!stay){
            navigate("/");
        }
        setStay(false);
    }
    catch(err){
        console.log("error submitting form",err);
        throw err;
    }
    };

    return (
        <>
            <Header activeMenu={"createAdSpace"} />
            <div className="create-container">
                <div className="label" style={{ padding: "46px 0" }}>
                    <div className="h-1-wrapper">
                        <h1 className="text-wrapper">{id ? "Edit" : "Create"} ad space</h1>
                    </div>
                </div>

                <form onSubmit={handleSubmit(onSubmit,onError)}>
                    <div className="data-section">
                        <h1 className="text-wrapper">OOH Medium Type</h1>
                        <div className="d-flex justify-content-between">
                            <div className="d-grid">
                                <label htmlFor="category" className="category-title">Category</label>
                                <select {...register("category")}
                                    style={{ width: "370px" }}
                                    className="category-dropdown"
                                    id="category"
                                    onChange={(e) => handleCategoryChange(e.target.value)}>
                                    <option value="">Category</option>
                                    {categories?.map((category) => (
                                        <option key={category.name} value={category.name}>{replacementMap[category.name]}</option>
                                    ))}
                                </select>
                                {errors.category && <span className="invalid-feedback d-block">{errors.category.message}</span>}
                            </div>

                            <div className="d-grid">
                                <label htmlFor="subcategory" className="category-title">Subcategory</label>
                                <select {...register("subCategory")} className="category-dropdown" id="subcategory" style={{ width: "280px" }}
                                
                                onChange={(e) => handleSubCategoryChange(e.target.value)}>
                                    <option value="">Subcategory</option>
                                    {subCategories?.map((subcategory) => (
                                        <option key={subcategory} value={subcategory}>{subcategory}</option>
                                    ))}
                                </select>
                                {errors.subcategory && <span className="invalid-feedback d-block">{errors.subcategory.message}</span>}
                            </div>


                            <div className="d-grid">
                                <label htmlFor="listing_title_en" className="category-title">Listing Title</label>
                                <input {...register("listing_title_en")} className="create-input" type="text" placeholder="Listing Title" style={{ width: "280px" }} />
                                {errors.listing_title_en && <span className="invalid-feedback d-block">{errors.listing_title_en.message}</span>}
                            </div>

                            
                        </div>
                    </div>

                    {selectedCategory && (
                        <div className="data-section">
                            {selectedFields.includes("location") && selectedCategory!=="TransitMedia" && (
                                <div className="data-section ">
                                    <h1 className="text-wrapper">Location Details</h1>
                                    <div className="pl">
                                    <div className="d-flex justify-content-between" >
                                    <div className="d-grid">
                                            <label htmlFor="city" className="category-title">City</label>
                                            <select {...register("city")} style={{ width: "370px" }} className="category-dropdown" id="city" onClick={(e) => setSelectedCity(e.target.value)}>
                                                <option value="">City</option>
                                                {statesData.data.map((city) => (
                                                    <option key={city.governorate_name_en} value={city.governorate_name_en}>{city.governorate_name_en}</option>
                                                ))}
                                            </select>
                                            {errors.city && <span className="invalid-feedback d-block">{errors.city.message}</span>}
                                        </div>

                                        <div className="d-grid">
                                            <label htmlFor="district" className="category-title">District</label>
                                            <select {...register("district")} style={{ width: "370px" }} className="category-dropdown" id="district">
                                                <option value="">District</option>
                                                {citiesData.data.filter(item => item.governorate_id === statesData.data.find(state => state.governorate_name_en === selectedCity)?.id).map((district) => (
                                                    <option key={district.city_name_en} value={district.city_name_en}>{district.city_name_en}</option>
                                                ))}
                                            </select>
                                            {errors.district && <span className="invalid-feedback d-block">{errors.district.message}</span>}
                                        </div>
                                    </div>    
                                    <div className="d-flex justify-content-between" >
                                        <div className="d-grid">
                                            <label className="category-title">Choose Location</label>
                                            {/* to do new function to switch between options and they all set location */}
                                            <Button variant="primary" onClick={handleShow}>
                                                Enter Ad location
                                            </Button>

                                            <Modal                                     
                                            dialogClassName="modal-90w "
                                            className="location-modal"
                                            show={modalShow} onHide={handleClose}>
                                                <Modal.Header closeButton>
                                                <Modal.Title>Choose a method from the following</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    
                                                <SearchMap setAdLocation={setAdLocation} />
                                                </Modal.Body>
                                                <Modal.Footer>
                                                <Button variant="secondary" onClick={handleClose}>
                                                    Close
                                                </Button>
                                                <Button variant="primary" onClick={handleClose}>
                                                    Save Changes
                                                </Button>
                                                </Modal.Footer>
                                            </Modal>

                                            Lat: {adLocation[0]} Long: {adLocation[1]}
                                        </div>

                                        <div className="d-grid">
                                            <label htmlFor="landmark" className="category-title">Landmark</label>
                                            <input {...register("landmark")} style={{ width: "550px" }} className="create-input" type="text" placeholder="Landmark, Building, etc" />
                                            {errors.landmark && <span className="invalid-feedback d-block">{errors.landmark.message}</span>}
                                        </div>
                                    </div>
                                    </div>
                                </div>

                            )}
                              {
                                selectedCategory ==='TransitMedia' && (
                                    <div className="data-section ">
                                    <h1 className="text-wrapper">Start point</h1>
                                    <div className="pl">
                                    <div className="d-flex justify-content-between" >
                                    <div className="d-grid">
                                            <label htmlFor="city" className="category-title">City</label>
                                            <select {...register("city")} style={{ width: "370px" }} className="category-dropdown" id="city" onClick={(e) => setSelectedCity(e.target.value)}>
                                                <option value="">City</option>
                                                {statesData.data.map((city) => (
                                                    <option key={city.governorate_name_en} value={city.governorate_name_en}>{city.governorate_name_en}</option>
                                                ))}
                                            </select>
                                            {errors.city && <span className="invalid-feedback d-block">{errors.city.message}</span>}
                                        </div>

                                        <div className="d-grid">
                                            <label htmlFor="district" className="category-title">District</label>
                                            <select {...register("district")} style={{ width: "370px" }} className="category-dropdown" id="district">
                                                <option value="">District</option>
                                                {citiesData.data.filter(item => item.governorate_id === statesData.data.find(state => state.governorate_name_en === selectedCity)?.id).map((district) => (
                                                    <option key={district.city_name_en} value={district.city_name_en}>{district.city_name_en}</option>
                                                ))}
                                            </select>
                                            {errors.district && <span className="invalid-feedback d-block">{errors.district.message}</span>}
                                        </div>
                                    </div>    
                                    <div className="d-flex justify-content-between" >
                                        <div className="d-grid">
                                            <label className="category-title">Search Location</label>
                                            <SearchMap setAdLocation={setAdLocation} />
                                            Lat: {adLocation[0]} Long: {adLocation[1]}
                                        </div>

                                        <div className="d-grid">
                                            <label htmlFor="landmark" className="category-title">Landmark</label>
                                            <input {...register("landmark")} style={{ width: "550px" }} className="create-input" type="text" placeholder="Landmark, Building, etc" />
                                            {errors.landmark && <span className="invalid-feedback d-block">{errors.landmark.message}</span>}
                                        </div>
                                    </div>
                                    </div>
                                    <h1 className="text-wrapper">End point</h1>
                                    <div className="pl">
                                    <div className="d-flex justify-content-between" >
                                    <div className="d-grid">
                                            <label htmlFor="city" className="category-title">City</label>
                                            <select {...register("city")} style={{ width: "370px" }} className="category-dropdown" id="city" onClick={(e) => setSelectedCity(e.target.value)}>
                                                <option value="">City</option>
                                                {statesData.data.map((city) => (
                                                    <option key={city.governorate_name_en} value={city.governorate_name_en}>{city.governorate_name_en}</option>
                                                ))}
                                            </select>
                                            {errors.city && <span className="invalid-feedback d-block">{errors.city.message}</span>}
                                        </div>

                                        <div className="d-grid">
                                            <label htmlFor="district" className="category-title">District</label>
                                            <select {...register("district")} style={{ width: "370px" }} className="category-dropdown" id="district">
                                                <option value="">District</option>
                                                {citiesData.data.filter(item => item.governorate_id === statesData.data.find(state => state.governorate_name_en === selectedCity)?.id).map((district) => (
                                                    <option key={district.city_name_en} value={district.city_name_en}>{district.city_name_en}</option>
                                                ))}
                                            </select>
                                            {errors.district && <span className="invalid-feedback d-block">{errors.district.message}</span>}
                                        </div>
                                    </div>    
                                    <div className="d-flex justify-content-between" >
                                        <div className="d-grid">
                                            <label className="category-title">Search Location</label>
                                            <SearchMap setAdLocation={setAdLocation} />
                                            Lat: {adLocation[0]} Long: {adLocation[1]}
                                        </div>

                                        <div className="d-grid">
                                            <label htmlFor="landmark" className="category-title">Landmark</label>
                                            <input {...register("landmark")} style={{ width: "550px" }} className="create-input" type="text" placeholder="Landmark, Building, etc" />
                                            {errors.landmark && <span className="invalid-feedback d-block">{errors.landmark.message}</span>}
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            

                                )
                            }
                          

                            <div className="d-flex justify-content-between">
                                {selectedFields.includes("size") && (
                                    <div className="data-section">
                                        <h1 className="text-wrapper">Dimensions (m)</h1>
                                        
                                        <div className="d-flex" style={{ gap: "30px" }}>
                                            <input
                                                {...register("width")}
                                                className="create-input"
                                                type="number"
                                                step="0.01"
                                                placeholder="Width"
                                            />

                                            <p style={{
                                                display: "flex",
                                                height: "64px",
                                                alignItems: "center"
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M23.7072 0.292786C23.5196 0.105315 23.2653 0 23.0002 0C22.735 0 22.4807 0.105315 22.2932 0.292786L12.0002 10.5858L1.70715 0.292786C1.51963 0.105315 1.26532 0 1.00015 0C0.734988 0 0.48068 0.105315 0.293153 0.292786C0.105682 0.480314 0.000366211 0.734622 0.000366211 0.999786C0.000366211 1.26495 0.105682 1.51926 0.293153 1.70679L10.5862 11.9998L0.293153 22.2928C0.105682 22.4803 0.000366211 22.7346 0.000366211 22.9998C0.000366211 23.2649 0.105682 23.5193 0.293153 23.7068C0.48068 23.8943 0.734988 23.9996 1.00015 23.9996C1.26532 23.9996 1.51963 23.8943 1.70715 23.7068L12.0002 13.4138L22.2932 23.7068C22.4807 23.8943 22.735 23.9996 23.0002 23.9996C23.2653 23.9996 23.5196 23.8943 23.7072 23.7068C23.8946 23.5193 23.9999 23.2649 23.9999 22.9998C23.9999 22.7346 23.8946 22.4803 23.7072 22.2928L13.4142 11.9998L23.7072 1.70679C23.8946 1.51926 23.9999 1.26495 23.9999 0.999786C23.9999 0.734622 23.8946 0.480314 23.7072 0.292786Z" fill="#374957" />
                                                </svg>
                                            </p>

                                            <input
                                                {...register("height")}
                                                className="create-input"
                                                type="number"
                                                step="0.01"
                                                placeholder="Height"
                                            />

                                        </div>
                                        {errors.height && <span className="invalid-feedback d-block">{errors.height.message}</span>}
                                        {errors.width && <span className="invalid-feedback d-block">{errors.width.message}</span>}
                                    </div>
                                )}

                                {selectedFields.includes("price") && (
                                    <div className="data-section">
                                        <h1 className="text-wrapper">Price</h1>
                                        <input
                                            {...register("price")}
                                            id="price"
                                            className="create-input"
                                            type="number"
                                            step="0.01"
                                            placeholder="eg: 1000.00"
                                        />
                                        {errors.price && <span className="invalid-feedback d-block">{errors.price.message}</span>}
                                    </div>
                                )}

                                {selectedFields.includes("pricing_unit") && (
                                    <div className="data-section">
                                        <h1 className="text-wrapper">Pricing Unit</h1>
                                        <select
                                            {...register("pricing_unit")}
                                            className="create-input"
                                            id="pricing_unit">
                                            <option value="">Select</option>
                                            <option value="per month">per month</option>
                                            <option value="per day">per day</option>
                                            <option value="per hour">per hour</option>
                                        </select>
                                        {errors.pricing_unit && <span className="invalid-feedback d-block">{errors.pricing_unit.message}</span>}
                                    </div>
                                )}
                            </div>

                            <div className="d-flex justify-content-between">
                                {selectedFields.includes("numberofAds") && (
                                    <div className="data-section">
                                        {/* todo set default as 1  */}
                                        <h1 className="text-wrapper">Number of Spaces</h1>
                                        <input
                                            {...register("numberofAds")}
                                            className="create-input"
                                            type="number"
                                            placeholder="eg.: 2,4,6,12"
                                        />
                                        {errors.numberofAds && <span className="invalid-feedback d-block">{errors.numberofAds.message}</span>}
                                    </div>
                                )}

                                {selectedFields.includes("main_img_url") && (
                                    <div className="data-section">
                                        <h1 className="text-wrapper">Image URL</h1>
                                        <FilePondUpload onUpload={handleImg} />
                                        {/* <input
                                            {...register("main_img_url")}
                                            className="create-input"
                                            type="text"
                                            placeholder="eg: https://img.jpg"
                                        />
                                        {errors.main_img_url && <span className="invalid-feedback d-block">{errors.main_img_url.message}</span>} */}
                                    </div>
                                )}

                                {/* {selectedFields.includes("status") && (
                                    <div className="data-section">
                                        <h1 className="text-wrapper">Status</h1>
                                        <div className="d-flex" style={{ gap: "70px" }}>
                                            <div className="form-check">
                                                <input
                                                    {...register("status")}
                                                    className="form-check-input"
                                                    type="radio"
                                                    id="availableRadio"
                                                    value="AVAILABLE"
                                                />
                                                <label className="form-check-label" htmlFor="availableRadio">
                                                    AVAILABLE
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    {...register("status")}
                                                    className="form-check-input"
                                                    type="radio"
                                                    id="notAvailableRadio"
                                                    value="NOT AVAILABLE"
                                                />
                                                <label className="form-check-label" htmlFor="notAvailableRadio">
                                                    NOT AVAILABLE
                                                </label>
                                            </div>
                                        </div>
                                        {errors.status && <span className="invalid-feedback d-block">{errors.status.message}</span>}
                                    </div>
                                )} */}
                                <div className="d-grid">
                                <label htmlFor="listing_id" className="category-title">Listing Code</label>
                                <input {...register("listing_id")} value={adCode} 
        onChange={(e) => setAdCode(e.target.value)} className="create-input" type="text" placeholder="Listing Code"  />
                                {errors.listing_id && <span className="invalid-feedback d-block">{errors.listing_id.message}</span>}
                                
                                {/* <input className="create-input upMargin" type="text" placeholder="Vendor Name" onBlur={handleVendorName}/>
                                    <button type="button" disabled={isCodeDisabled} className="btn btn-primary dib" onClick={handleCodeGen}>Generate Code</button> */}
                            </div>
                            </div>

                            <div className="d-flex" style={{ gap: "140px" }}>
                                {/* {selectedFields.includes("classType") && (
                                    <div className="data-section">
                                        <h1 className="text-wrapper">Class</h1>
                                        <div className="form-check">
                                            <input
                                                {...register("classType")}
                                                className="form-check-input"
                                                type="radio"
                                                id="classARadio"
                                                value="Class A"
                                            />
                                            <label className="form-check-label" htmlFor="classARadio">
                                                Class A
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                {...register("classType")}
                                                className="form-check-input"
                                                type="radio"
                                                id="classBRadio"
                                                value="Class B"
                                            />
                                            <label className="form-check-label" htmlFor="classBRadio">
                                                Class B
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                {...register("classType")}
                                                className="form-check-input"
                                                type="radio"
                                                id="classCRadio"
                                                value="Class C"
                                            />
                                            <label className="form-check-label" htmlFor="classCRadio">
                                                Class C
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                {...register("classType")}
                                                className="form-check-input"
                                                type="radio"
                                                id="otherRadio"
                                                value="Other"
                                            />
                                            <label className="form-check-label" htmlFor="otherRadio">
                                                Other
                                            </label>
                                        </div>
                                        {errors.classType && <span className="invalid-feedback d-block">{errors.classType.message}</span>}
                                    </div>
                                )} */}

                                {selectedFields.includes("lighting") && (
                                    <div className="data-section">
                                        <h1 className="text-wrapper">Lighting</h1>
                                        <div className="form-check">
                                            <input
                                                {...register("lighting")}
                                                className="form-check-input"
                                                type="radio"
                                                id="yesRadio"
                                                value="true"
                                            />
                                            <label className="form-check-label" htmlFor="yesRadio">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                {...register("lighting")}
                                                className="form-check-input"
                                                type="radio"
                                                id="noRadio"
                                                value="false"
                                            />
                                            <label className="form-check-label" htmlFor="noRadio">
                                                No
                                            </label>
                                        </div>
                                        {errors.lighting && <span className="invalid-feedback d-block">{errors.lighting.message}</span>}
                                    </div>
                                )}

                                {selectedFields.includes("faces") && (
                                    <div className="data-section">
                                        <h1 className="text-wrapper">Faces</h1>
                                            <input
                                            {...register("faces")}
                                            className="create-input"
                                            type="number"
                                            placeholder="eg.: 1, 2 or 4 only"
                                        />
                                        {errors.faces && <span className="invalid-feedback d-block">{errors.faces.message}</span>}
                                    </div>
                                )}
                                
                            </div>

                            <div className="data-section">
                                <div className="d-flex justify-content-between">
                                    
                                    {selectedFields.includes("resolution") && (
                                        <div className="d-grid">
                                            <label htmlFor="resolution" className="category-title">Resolution</label>
                                            <input
                                                {...register("resolution")}
                                                id="resolution"
                                                className="create-input"
                                                type="number"
                                                placeholder="Pixels"
                                            />
                                            {errors.resolution && <span className="invalid-feedback d-block">{errors.resolution.message}</span>}
                                        </div>
                                    )}

                                    {selectedFields.includes("runTime") && (
                                        <div className="d-grid">
                                            <label htmlFor="runTime" className="category-title">Run time</label>
                                            <input
                                                {...register("runTime")}
                                                id="runTime"
                                                className="create-input"
                                                type="number"
                                                placeholder="Seconds"
                                            />
                                            {errors.runTime && <span className="invalid-feedback d-block">{errors.runTime.message}</span>}
                                        </div>
                                    )}

                                    {selectedFields.includes("round") && (
                                        <div className="d-grid">
                                            <label htmlFor="round" className="category-title">Round</label>
                                            <input
                                                {...register("round")}
                                                id="round"
                                                className="create-input"
                                                type="number"
                                                placeholder="Minutes"
                                            />
                                            {errors.round && <span className="invalid-feedback d-block">{errors.round.message}</span>}
                                        </div>
                                    )}
                                    {selectedFields.includes("description") && (
                                        <div className="d-grid" style={{width:"80%"}}>
                                        <h1 className="text-wrapper">Ad description</h1>
                                            <textarea
                                            
                                                style={{width:"80%"}}
                                                {...register("description")}
                                                id="description"
                                                className="create-input"
                                                placeholder="Info"
                                            />
                                            {errors.description && <span className="invalid-feedback d-block">{errors.description.message}</span>}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                            <button
                                type="submit"
                                className="btn submit-btn" disabled={loadingBtn} style={{ width: "auto" }}>
                                {loadingBtn && (
                                    <span
                                        style={{ marginRight: "15px" }}
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"></span>
                                )}
                                {id ? "Edit" : "Create"} Ad Space
                            </button>
                            {!id? <>
                                <button
                                type="submit"
                                onClick={()=>setStay(true)}
                                className="btn submit-btn" disabled={loadingBtn} style={{ width: "auto" }}>
                                {loadingBtn && (
                                    <span
                                        style={{ marginRight: "15px" }}
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"></span>
                                )}
                                Save and Create Another Ad Space
                            </button>
                            </>:<></>}
                            </div>
                            
                        </div>
                    )}
                </form>
            </div>
        </>
    );
};

export default CreateAdSpace;
