import {
    createStateHook,
    createActionsHook,
    createEffectsHook,
  } from "overmind-react";
  import { state } from "./state";
  import * as actions from "./actions";
  import * as effects from "./gateways";
  
  export const config = {
    state,
    actions,
    effects,
  };
  
  export const useAppState = createStateHook();
  export const useActions = createActionsHook();
  export const useEffects = createEffectsHook();
  