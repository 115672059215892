import React,{useState } from "react";
import Header from '../header';
import './contactUs.css'
import { Link } from 'react-router-dom';
import Footer from '../footer/Footer';

const ContactUs = ()=> {
    const [isLoading, setIsLoading] = useState(false);
return (
    <>
    <Header activeMenu={"Contact us"} />
    <div className="contact-us container">
    <div className="header-con-works">
               
               <div className="header-underline-works">
               <h1 className="signUp-header">Contact Us</h1>
               <div className="login-line" style={{width:'100px'}}></div>
               </div>
               </div>
               <div className="all-contact-info d-flex col-md-12">
               <div className="contact-detailss col-md-6">
                <div className="call-us">
                    <div className="call-header d-flex align-items-center">
                    <div>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="40" height="40" rx="20" fill="#02416F"/>
<path d="M18.5542 14.24L15.1712 10.335C14.7812 9.885 14.0662 9.887 13.6132 10.341L10.8312 13.128C10.0032 13.957 9.76623 15.188 10.2452 16.175C13.1069 22.1 17.8853 26.8851 23.8062 29.755C24.7922 30.234 26.0222 29.997 26.8502 29.168L29.6582 26.355C30.1132 25.9 30.1142 25.181 29.6602 24.791L25.7402 21.426C25.3302 21.074 24.6932 21.12 24.2822 21.532L22.9182 22.898C22.8484 22.9712 22.7565 23.0194 22.6566 23.0353C22.5567 23.0512 22.4543 23.0339 22.3652 22.986C20.1357 21.7021 18.2862 19.8502 17.0052 17.619C16.9573 17.5298 16.9399 17.4272 16.9558 17.3272C16.9717 17.2271 17.02 17.135 17.0932 17.065L18.4532 15.704C18.8652 15.29 18.9102 14.65 18.5542 14.239V14.24Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                    </div>
                    <h2 className="call-us-header">Call To Us</h2>
                    </div>
                    <p>We are available 24/7</p>
                    <p> <b>Phone:</b> +2 010 6213 4373</p>
                    <p></p>
                </div>
                <div className="divider-contact"></div>
                <div className="message-us">
                    <div className="msg-header d-flex align-items-center">
                    <div>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="40" height="40" rx="20" fill="#02416F"/>
<path d="M10 13L20 20L30 13M10 27H30V13H10V27Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                    </div>
                    <h2 className="msg-us-header">Write To US</h2>
                    </div>
                    <div className="msg-content">
                        <p>Fill out our form and we will contact you within 24 hours.</p>
                        <p><b>Email:</b><a href="mailto:hello@mashy.live">hello@mashy.live</a></p>
                    </div>
                </div>
                <div className="divider-contact"></div>
                <div className="sign-up-contact">
                    <div className='sign-contact-msg'>We want to stay in touch! Leave us your email and we'll be sending you - no spam though - all our news and updates.</div>
                    <form className="email-con d-flex">
                        <input className="form-control  email-sign-up" type="search" placeholder="Enter your email adress" aria-label="Search" />
                        <button className="btn email-btn" type="button">
                            Subscribe
                        </button>
                    </form>
                    <div className="social-con d-flex align-items-baseline">
                        <div className="face-bookk d-flex align-items-center justify-content-center">
                            <a href="https://www.facebook.com/mashyliveads" target="_blank">
                        <svg width="24" height="24" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="#ffffff" d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"></path></svg>
                        </a>
                        </div>
                        <div className="insta-gram d-flex align-items-center justify-content-center">
                        <a href="https://www.instagram.com/startupswithoutborders/reel/CserYIGP0Zq/" target="_blank">
                        <svg  width="24" height="24" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="#ffffff" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path></svg>
                        </a>
                        </div>
                        <div className="linked-in d-flex align-items-center justify-content-center">
                            <a href="https://www.linkedin.com/company/mashy-live/" target="_blank">
                        <svg width="24" height="24" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.06435 3.70969H0.303162V11.2269H4.06435V3.70969Z" fill="#FCFBFB"></path><path d="M14.2388 3.54677C14.1002 3.53591 13.9529 3.53048 13.8055 3.52504C11.6996 3.47073 10.5123 4.25287 10.0963 4.58962C9.98367 4.68196 9.93167 4.73627 9.93167 4.73627V3.73144H6.33514V11.2486H9.93167H10.0963C10.0963 10.4828 10.0963 9.72239 10.0963 8.95655C10.0963 8.54376 10.0963 8.13096 10.0963 7.71817C10.0963 7.20761 10.0357 6.66446 10.443 6.19735C10.7896 5.80628 11.4136 5.61074 12.1156 5.61074C14.1955 5.61074 14.2388 6.78938 14.2388 6.89801C14.2388 6.90344 14.2388 6.90887 14.2388 6.90887V11.2812H18V6.37659C18 4.69825 16.6394 3.70972 14.2388 3.54677Z" fill="#FCFBFB"></path><path d="M2.18391 2.73748C3.39006 2.73748 4.36784 2.12467 4.36784 1.36874C4.36784 0.612805 3.39006 0 2.18391 0C0.977769 0 0 0.612805 0 1.36874C0 2.12467 0.977769 2.73748 2.18391 2.73748Z" fill="#FCFBFB"></path></svg>  
                        </a>
                                              </div>
                    </div>
                </div>
               </div>
                <div className="contact-form col-md-6">
                <form className="signup-form" >
                <div className="form-group">
                    <label className="form-control-label email-label" style={{paddingTop:'0px'}}>First Name</label>
                    <input
                        
                        type="text"
                        className="form-control email-input"
                        placeholder="Enter your first name"
                    />
                </div>
                

                
                

                <div className="form-group">
                    <label className="form-control-label password-label">Email</label>
                    <input
                        
                        type="email"
                        className="form-control password-input"
                        placeholder="Enter your email"
                    />
                </div>
                

                <div className="form-group">
                    <label className="form-control-label password-label">Phone Number</label>
                    <input
                       
                        type="phone"
                        className="form-control password-input"
                        placeholder="Enter your phone number"
                    />
                </div>
                

                <div className="form-group">
                    <label className="form-control-label text-label">Message</label>
                    <div className="pass-group" id="messageArea">
                    <textarea id="messageArea"  placeholder="Enter your Message" className="form-control-area text-area" rows="6" cols="6" >
                       
                        </textarea>
                        
                        
                    </div>
                   
                </div>
                

                <div className="d-flex align-items-center justify-content-center">
                    <button className="btn btn-start signUp-btn" type="button" 
                   
                    >
                         {isLoading && <span className="spinner-border spinner-border-sm" style={{marginRight:"16px"}} role="status" aria-hidden="true"></span>}
                        Send Message
                    </button>
                </div>
                
            </form>
                </div>
               

               </div>

    </div>
    <Footer/>
    </>
)
}
export default ContactUs;