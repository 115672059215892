import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useLocation, useNavigate } from "react-router-dom";
import { useActions } from "../../presenter";
import { ResetPasswordFormValidator } from "../../presenter/validators/reset-password.validator";
import { toast } from "react-toastify";
const ResetPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState(null);
    const { resetPassword } = useActions();
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(ResetPasswordFormValidator),
    });

    const onSubmit = async (data) => {
        try{
        if (password !== confirmPassword) {
            setError("Passwords do not match");

            toast.error("Passwords do not match");
            return
        }
        const token = new URLSearchParams(location.search).get("token");
        const email = new URLSearchParams(location.search).get("email");
        if(! email || !token){
            toast.error('No Email/Token found');
            return
        }
        // check if there is a token and throw error if not 
        const response = await resetPassword({ newPassword: data.newPassword, token, email });
        if(!response){
            toast.error("Bad request! please try again later");
            return;
        }
        navigate("/login");
        }
        catch(err){
            // console.error("error reseting password",err);
            toast.error("Bad request! please try again later");
            // throw err;
            //? throw error or error card
        }
    }

    return (
        <>
            <div className="container reset-password-container">
                <h1 className="password-header mb-44">Setup New Password</h1>
                <form onSubmit={handleSubmit(async (data) => await onSubmit(data))}>
                    <div className="form-group">
                        <label className="form-control-label">Password</label>
                        <div className="pass-group" id="passwordInput">
                            <input
                                {...register("newPassword")}
                                onChange={(e) => setPassword(e.target.value)}
                                className="form-control form-control-input pass-input"
                                placeholder="Enter your password"
                                type="password" />
                            {/* <span onClick={onEyeClick} className={`fa toggle-password feather-eye" ${eye ? "fa-eye" : "fa-eye-slash"}`} />
                        <span className="pass-checked">
                            <i className="feather-check" />
                        </span> */}
                        </div>
                        <p className="invalid-feedback d-block">
                            {errors.newPassword?.message}
                        </p>
                    </div>
                    <div className="form-group ">
                        <label className="form-control-label">
                            Confirm Password
                        </label>
                        <div className="pass-group" id="passwordInputs">
                            <input
                                {...register("confirmPassword")}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="form-control form-control-input pass-input"
                                placeholder="Enter your password"
                                type="password" />
                            {/* <span onClick={onEyeClick2} className={`fa toggle-password feather-eye" ${eye2 ? "fa-eye" : "fa-eye-slash"}`} />
                        <span className="pass-checked">
                            <i className="feather-check" />
                        </span> */}
                        </div>
                        <p className="invalid-feedback ">
                            {errors.confirmPassword?.message}
                        </p>
                        {error && <p className="invalid-feedback ">
                            {error}
                        </p>}
                    </div>
                    <div className="">
                        <button className="btn submit-btnTwo d-flex justify-content-center align-items-center" type="submit">
                            Reset Password
                        </button>
                    </div>
                    <div className="reset-account">
                        <p className="mb-0">
                            Already have an account? <Link to="/login">Sign in</Link>
                        </p>
                    </div>
                </form>
            </div>
        </>
    );
};

export default ResetPassword;