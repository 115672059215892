import { redirect } from "react-router-dom";
import { toast } from "react-toastify";

export class InternalServerError extends Error {
  constructor(message) {
    super();
    this.name = "InternalServerError";
  }

  handle() {
    if (this.message) {
      toast.error(`InternalServerError: ${this.message}`, { position: "top-right" });
    }

    redirect("/500");
  }
}

export class UnexpectedError extends Error {
  constructor(message) {
    super(message);
    this.name = "UnexpectedError";
  }

  handle() {
    if (this.message) {
      toast.error(`Unexpected error, please try again~ CODE:${this.message}`, { position: "top-right" });
    } else {
      toast.error("Unexpected error, please try again", {
        position: "top-right",
      });
    }
  }
}

export class UnauthenticatedError extends Error {
  message = "Unauthenticated";

  constructor(message) {
    super(message);
    this.name = "UnauthenticatedError";
  }

  handle() {
    if (this.message) {
      toast.error(`UnauthenticatedError : ${this.message}`, { position: "top-right" });
    }

    redirect("/auth/login");
  }
}

export class ForbiddenError extends Error {
  constructor(message) {
    super(message);
    this.name = "ForbiddenError";
  }

  handle() {
    if (this.message) {
      toast.error(`You're not authorized to make access this action.code: ${this.message}`, { position: "top-right" });
    } else {
      toast.error("You're not authorized to make access this action", {
        position: "top-right",
      });
    }

    redirect("/");
  }
}

export class BadRequestError extends Error {
  constructor(message) {
    super(message);
    this.name = "BadRequestError";
  }

  handle() {
    if (this.message) {
      toast.error(`Bad Request, please try again with correct data, code:${this.message}`, { position: "top-right" });
    } else {
      toast.error("Bad Request, please try again with correct data", {
        position: "top-right",
      });
    }
  }
}

export function handleError(error) {
  
  console.log("handle err presenter",error);
  if(error instanceof BadRequestError){
    error.handle();
  }
  else{
    toast.error("Bad Request, please try again with correct data", {
      position: "top-right",
    });
  }
}
