import React from 'react';
import { Link } from 'react-router-dom';
import { useAppState } from '../../presenter';
import Swal from "sweetalert2";
import { LazyLoadImage } from "react-lazy-load-image-component";

const GridView = ({
    filteredAdvertisingSpaces,
    onClick: handleQuantityChange,
    onDelete: handleDeleteAd
}) => {
    const { user } = useAppState();
    
    return (
        // <>
        //    <div className="grid-view d-flex flex-wrap" >
        //         {filteredAdvertisingSpaces?.map((advertisingSpace) => (
        //             <div key={advertisingSpace._id} className="card">
                        
        //                 <Link  to={`/listing/${advertisingSpace._id}`}>
        //                 <img src={advertisingSpace.main_img_url} className="card-img-top" alt={advertisingSpace.listing_id} />
        //                 </Link>
        //                 <div className="card-body">
        //                     <div className="location-price-con">
        //                     {/* <h5 className="card-title">{advertisingSpace.code}</h5> */}
        //                     <div className="location-container">
        //                         <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 13.85 16.859">
        //                             <g data-name="Group 414">
        //                                 <path data-name="Location Icon" d="M13.534 4.844A6.928 6.928 0 1 0 .319 9.01c.934 3.348 6.611 7.849 6.611 7.849s5.669-4.509 6.6-7.849a7.033 7.033 0 0 0 .004-4.166zm-.833 3.9-.008.016v.016c-.631 2.258-3.987 5.459-5.77 6.962-1.775-1.511-5.139-4.7-5.77-6.962v-.031a6.054 6.054 0 0 1-.28-1.823l-.008-.008a6.058 6.058 0 1 1 11.836 1.83z" />
        //                                 <g data-name="Group 413">
        //                                     <path data-name="Path 13835" d="M8.745 1.285a6.052 6.052 0 0 0-7.88 5.77l.008.008a6.053 6.053 0 0 0 .28 1.822v.031c.631 2.259 3.995 5.451 5.77 6.962 1.783-1.5 5.139-4.7 5.77-6.962v-.015l.008-.016a6.057 6.057 0 0 0-3.956-7.6zm.023 7.584a2.6 2.6 0 1 1 0-3.676 2.6 2.6 0 0 1 .001 3.677z" />
        //                                 </g>
        //                             </g>
        //                         </svg>
        //                         <span className="location-text">{advertisingSpace.listing_title_en}</span>
        //                     </div>
        //                     {
        //                         advertisingSpace.location.district && 
        //                         <div className="street-container">
        //                             <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
        //                                 <path data-name="Path 15221" d="M1125.42 332.667a5 5 0 1 1 5-5 5 5 0 0 1-5 5z" transform="translate(-1120.42 -322.667)" fill="#e2c227" fillRule="evenodd" />
        //                             </svg>
        //                             <span className="street-text">{advertisingSpace.location.district}</span>
        //                         </div>
        //                     }
                            
        //                     <p className="price-text" >{advertisingSpace.price} {advertisingSpace.currency || "EGP"}</p>
        //                     </div>
        //                     <div className="separator"></div>
                            
        //                     <div  className='d-flex' style={{justifyContent:'space-between', marginBottom:'20px'}}>
        //                         <Link className="btn details-btn" to={`/listing/${advertisingSpace._id}`}>
        //                             View Details
        //                         </Link>

        //                         {(!user || user.role.toLowerCase() === "user") && !advertisingSpace.quantity && (
        //                             <svg className="cart-svg" onClick={() => handleQuantityChange(advertisingSpace._id, 1)} xmlns="http://www.w3.org/2000/svg" width="43.399" height="40" viewBox="0 0 43.399 40">
        //                                 <g data-name="Group 450" transform="translate(-702.964 12212.24)">
        //                                     <rect data-name="Rectangle 307" width="43.399" height="40" rx="9" transform="translate(702.964 -12212.24)" fill="#e2c227" />
        //                                     <path data-name="Path 13837" d="M1282.3 41.8a2.1 2.1 0 1 0 2.1 2.1 2.094 2.094 0 0 0-2.1-2.1zm-6.3-15.75a1.051 1.051 0 0 0 1.05 1.05h1.05l3.78 7.969-1.42 2.562a2.106 2.106 0 0 0 1.84 3.118h11.55a1.05 1.05 0 1 0 0-2.1h-11.55l1.15-2.1h7.83a2.079 2.079 0 0 0 1.83-1.082l3.76-6.814a1.043 1.043 0 0 0-.91-1.554h-15.54l-.7-1.5a1.05 1.05 0 0 0-.95-.6h-1.72a1.051 1.051 0 0 0-1.05 1.051zm16.8 15.75a2.1 2.1 0 1 0 2.1 2.1 2.094 2.094 0 0 0-2.1-2.1z" transform="translate(-561.536 -12227.74)" fill="#f7f6f9" fillRule="evenodd" />
        //                                 </g>
        //                             </svg>
                                
        //                         )}

        //                         

        //                         {user && user.role.toLowerCase() === "admin" && (
        //                             <div className="d-flex align-items-center">
        //                                 <Link to={`/admin/create-adspace?id=${advertisingSpace._id}`}>
        //                                     <svg className="cart-svg" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        //                                         <g clipPath="url(#clip0_158_227)">
        //                                             <path d="M28.5302 1.47006C27.5748 0.557124 26.3042 0.0476685 24.9827 0.0476685C23.6612 0.0476685 22.3906 0.557124 21.4352 1.47006L2.01394 20.8913C1.37382 21.5279 0.866268 22.2851 0.520617 23.1191C0.174966 23.9531 -0.00192038 24.8473 0.000186447 25.7501V28.1251C0.000186447 28.6223 0.19773 29.0993 0.549361 29.4509C0.900992 29.8025 1.37791 30.0001 1.87519 30.0001H4.25019C5.15338 30.0025 6.04808 29.8258 6.88252 29.4801C7.71695 29.1345 8.47454 28.6267 9.11144 27.9863L28.5302 8.56506C29.4697 7.62348 29.9973 6.34768 29.9973 5.01756C29.9973 3.68744 29.4697 2.41164 28.5302 1.47006ZM6.45769 25.3351C5.87067 25.9183 5.07766 26.247 4.25019 26.2501H3.75019V25.7501C3.75275 24.9217 4.08148 24.1278 4.66519 23.5401L19.1252 9.08381L20.9164 10.8751L6.45769 25.3351ZM25.8752 5.91381L23.5677 8.22506L21.7752 6.43256L24.0877 4.12506C24.3289 3.89454 24.6497 3.76589 24.9833 3.76589C25.317 3.76589 25.6377 3.89454 25.8789 4.12506C26.1147 4.36326 26.2466 4.68507 26.2459 5.02021C26.2452 5.35535 26.1119 5.6766 25.8752 5.91381Z" fill="#E2C227"/>
        //                                         </g>
        //                                         <defs>
        //                                             <clipPath id="clip0_158_227">
        //                                             <rect width="30" height="30" fill="white"/>
        //                                             </clipPath>
        //                                         </defs>
        //                                     </svg>
        //                                 </Link>

        //                                 <svg className="cart-svg" xmlns="http://www.w3.org/2000/svg" width="30" height="34" viewBox="0 0 30 34" fill="none"
        //                                 onClick={() => {
        //                                     Swal.fire({
        //                                       title: "Delete Confirmation",
        //                                       text: "Are you sure you want to delete this?",
        //                                       icon: "warning",
        //                                       showCancelButton: true,
        //                                       confirmButtonColor: "#3085d6",
        //                                       cancelButtonColor: "#d33",
        //                                       cancelButtonText: "Cancel",
        //                                       confirmButtonText: "Delete",
        //                                     }).then((result) => {
        //                                       if (result.isConfirmed) {
        //                                         handleDeleteAd(advertisingSpace._id);
        //                                       }
        //                                     });
        //                                   }}>
        //                                     <path d="M10 0H20V2.42857H10V0Z" fill="#F46A6A"/>
        //                                     <path d="M0 4.85712V7.28569H2.5V31.5714C2.5 32.2155 2.76339 32.8332 3.23223 33.2887C3.70107 33.7441 4.33696 34 5 34H25C25.663 34 26.2989 33.7441 26.7678 33.2887C27.2366 32.8332 27.5 32.2155 27.5 31.5714V7.28569H30V4.85712H0ZM5 31.5714V7.28569H25V31.5714H5Z" fill="#F46A6A"/>
        //                                     <path d="M17.5 12.1429H20V26.7143H17.5V12.1429Z" fill="#F46A6A"/>
        //                                     <path d="M10 12.1429H12.5V26.7143H10V12.1429Z" fill="#F46A6A"/>
        //                                 </svg>
        //                             </div>
        //                         )}
        //                     </div>
                            
        //                 </div>
        //             </div>
        //         }
        //     </div>
        // </>
        <>
        <div className="container grid-con"  >

    <div className="row">
    {filteredAdvertisingSpaces?.map((advertisingSpace) => (
        <div key={advertisingSpace._id} className="col-md-4 ">
        <div className="card">
            <div className="card-body">
            <Link  to={`/listing/${advertisingSpace._id}`}>
            <LazyLoadImage src={advertisingSpace.main_img_url[0]}
            placeholderSrc={"https://i.ibb.co/42Ck6pF/logo-BB-1.png"}
             className="card-img-top" alt={"advertisingSpace.listing_id"} />
            </Link>
            <div className="location-price-con" style={{padding: '0px 15px'}}>
                                {/* <h5 className="card-title">{advertisingSpace.code}</h5> */}
                                <div className="location-container">
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 13.85 16.859">
                                        <g data-name="Group 414">
                                            <path data-name="Location Icon" d="M13.534 4.844A6.928 6.928 0 1 0 .319 9.01c.934 3.348 6.611 7.849 6.611 7.849s5.669-4.509 6.6-7.849a7.033 7.033 0 0 0 .004-4.166zm-.833 3.9-.008.016v.016c-.631 2.258-3.987 5.459-5.77 6.962-1.775-1.511-5.139-4.7-5.77-6.962v-.031a6.054 6.054 0 0 1-.28-1.823l-.008-.008a6.058 6.058 0 1 1 11.836 1.83z" />
                                            <g data-name="Group 413">
                                                <path data-name="Path 13835" d="M8.745 1.285a6.052 6.052 0 0 0-7.88 5.77l.008.008a6.053 6.053 0 0 0 .28 1.822v.031c.631 2.259 3.995 5.451 5.77 6.962 1.783-1.5 5.139-4.7 5.77-6.962v-.015l.008-.016a6.057 6.057 0 0 0-3.956-7.6zm.023 7.584a2.6 2.6 0 1 1 0-3.676 2.6 2.6 0 0 1 .001 3.677z" />
                                            </g>
                                        </g>
                                    </svg> */}
                                    <span className="location-text">{advertisingSpace.listing_title_en}</span>
                                </div>
                                {
                                    advertisingSpace.location.district && 
                                    <div className="street-container">
                                        <svg  width="16" height="16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="location-dot" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="#333333"  d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"></path></svg>
                                        <span className="street-text">{advertisingSpace.location.district}</span>
                                    </div>
                                }
                                <div className="grid-price-con">
                               <p className="price-egp">EGP</p>
                                <p className="price-text" >{advertisingSpace.price.$numberDecimal}</p>
                                </div>
                                </div>
                                <div className="separator" style={{margin: '0px 15px 24px'}}></div>
                                
                                <div  className='d-flex' style={{justifyContent:'space-between', marginBottom:'20px', padding: '0px 15px'}}>
                                    <Link className="btn details-btn" to={`/listing/${advertisingSpace._id}`}>
                                        View Details
                                    </Link>

                                    {(!user || user.role.toLowerCase() === "user") && !advertisingSpace.quantity && (
                                        <svg className="cart-svg" onClick={() => handleQuantityChange(advertisingSpace._id, 1,advertisingSpace.price.$numberDecimal)} xmlns="http://www.w3.org/2000/svg" width="43.399" height="40" viewBox="0 0 43.399 40">
                                            <g data-name="Group 450" transform="translate(-702.964 12212.24)">
                                                <rect data-name="Rectangle 307" width="43.399" height="40" rx="9" transform="translate(702.964 -12212.24)" fill="#02416F" />
                                                <path data-name="Path 13837" d="M1282.3 41.8a2.1 2.1 0 1 0 2.1 2.1 2.094 2.094 0 0 0-2.1-2.1zm-6.3-15.75a1.051 1.051 0 0 0 1.05 1.05h1.05l3.78 7.969-1.42 2.562a2.106 2.106 0 0 0 1.84 3.118h11.55a1.05 1.05 0 1 0 0-2.1h-11.55l1.15-2.1h7.83a2.079 2.079 0 0 0 1.83-1.082l3.76-6.814a1.043 1.043 0 0 0-.91-1.554h-15.54l-.7-1.5a1.05 1.05 0 0 0-.95-.6h-1.72a1.051 1.051 0 0 0-1.05 1.051zm16.8 15.75a2.1 2.1 0 1 0 2.1 2.1 2.094 2.094 0 0 0-2.1-2.1z" transform="translate(-561.536 -12227.74)" fill="#f7f6f9" fillRule="evenodd" />
                                            </g>
                                        </svg>
                                    
                                    )}

                                    {user && user.role.toLowerCase() === "user" && advertisingSpace.quantity && (
                                        <div>
                                            <button type="button" 
                                            onClick={() => handleQuantityChange(advertisingSpace._id, -1,advertisingSpace.price.$numberDecimal)}
                                            className="btn btn-danger mt-4">
                                                X
                                            </button>
                                        </div>
                                    )}

                                    {user && user.role.toLowerCase() === "admin" && (
                                        <div className="d-flex align-items-center">
                                            <Link to={`/admin/create-adspace?id=${advertisingSpace._id}`}>
                                                <svg className="cart-svg" style={{marginRight:'20px'}} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                                    <g clipPath="url(#clip0_158_227)">
                                                        <path d="M28.5302 1.47006C27.5748 0.557124 26.3042 0.0476685 24.9827 0.0476685C23.6612 0.0476685 22.3906 0.557124 21.4352 1.47006L2.01394 20.8913C1.37382 21.5279 0.866268 22.2851 0.520617 23.1191C0.174966 23.9531 -0.00192038 24.8473 0.000186447 25.7501V28.1251C0.000186447 28.6223 0.19773 29.0993 0.549361 29.4509C0.900992 29.8025 1.37791 30.0001 1.87519 30.0001H4.25019C5.15338 30.0025 6.04808 29.8258 6.88252 29.4801C7.71695 29.1345 8.47454 28.6267 9.11144 27.9863L28.5302 8.56506C29.4697 7.62348 29.9973 6.34768 29.9973 5.01756C29.9973 3.68744 29.4697 2.41164 28.5302 1.47006ZM6.45769 25.3351C5.87067 25.9183 5.07766 26.247 4.25019 26.2501H3.75019V25.7501C3.75275 24.9217 4.08148 24.1278 4.66519 23.5401L19.1252 9.08381L20.9164 10.8751L6.45769 25.3351ZM25.8752 5.91381L23.5677 8.22506L21.7752 6.43256L24.0877 4.12506C24.3289 3.89454 24.6497 3.76589 24.9833 3.76589C25.317 3.76589 25.6377 3.89454 25.8789 4.12506C26.1147 4.36326 26.2466 4.68507 26.2459 5.02021C26.2452 5.35535 26.1119 5.6766 25.8752 5.91381Z" fill="#D8C303"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_158_227">
                                                        <rect width="30" height="30" fill="white"/>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </Link>

                                            <svg className="cart-svg" xmlns="http://www.w3.org/2000/svg" width="30" height="34" viewBox="0 0 30 34" fill="none"
                                            onClick={() => {
                                                Swal.fire({
                                                title: "Delete Confirmation",
                                                text: "Are you sure you want to delete this?",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                cancelButtonText: "Cancel",
                                                confirmButtonText: "Delete",
                                                }).then((result) => {
                                                if (result.isConfirmed) {
                                                    handleDeleteAd(advertisingSpace._id);
                                                }
                                                });
                                            }}>
                                                <path d="M10 0H20V2.42857H10V0Z" fill="#F46A6A"/>
                                                <path d="M0 4.85712V7.28569H2.5V31.5714C2.5 32.2155 2.76339 32.8332 3.23223 33.2887C3.70107 33.7441 4.33696 34 5 34H25C25.663 34 26.2989 33.7441 26.7678 33.2887C27.2366 32.8332 27.5 32.2155 27.5 31.5714V7.28569H30V4.85712H0ZM5 31.5714V7.28569H25V31.5714H5Z" fill="#F46A6A"/>
                                                <path d="M17.5 12.1429H20V26.7143H17.5V12.1429Z" fill="#F46A6A"/>
                                                <path d="M10 12.1429H12.5V26.7143H10V12.1429Z" fill="#F46A6A"/>
                                            </svg>
                                        </div>
                                    )}
                                </div>
            </div>
        </div>
        </div>
     ))}
     </div>
        </div>
        
        </>
    )
};
export default GridView;
