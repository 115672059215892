import * as Yup from "yup";

// const billBoardSchema=Yup.object().shape({

// });

// const digitalScreenSchema=Yup.object().shape({
    
// });

// const transitSchema=Yup.object().shape({
    
// });
// const streetSchema=Yup.object().shape({
    
// });
// const placeSchema=Yup.object().shape({
    
// });
// export const AdSpaceValidator = Yup.object().shape({
//     category: Yup.string().required('Category is required.'),
//     subCategory: Yup.string().required('Subcategory is required.'),
//     city: Yup.string().required('City is required.'),
//     district: Yup.string().required('District is required.'),
//     // area: Yup.string().required('Area is required.'),
//     // geoLocation: Yup.string().required('Geographical location is required.'),
//     landmark: Yup.string(),
//     width: Yup.number().positive('Width must be a positive number.').typeError('Width must be a number.').required('Width is required.'),
//     height: Yup.number().positive('Height must be a positive number.').typeError('Height must be a number.').required('Height is required.'),
//     price: Yup.number().positive('Price must be a positive number.').typeError('Price must be a number.').required('Price is required.'),
//     pricing_unit: Yup.string().required('Pricing unit is required.'),
//     listing_id: Yup.string().required('Listing code is required.'),
//     listing_title_en: Yup.string().required('Listing title is required.'),
//     numberofAds: Yup.number().integer().min(1, 'Number of Ads must be at least 1.').typeError('Number of Ads must be a number.').required('Number of Ads is required.'),
//     // main_img_url: Yup.string().url('Please enter a valid URL.').required('Image URL is required.'),
//    // classType: Yup.string().required('Class is required.'),
//     lighting: Yup.boolean(),
//     faces: Yup.string(),
//     // todo resolve conflict with transit media category
//     resolution: Yup.number().integer().positive('Resolution must be a positive number.').typeError('Resolution must be a number.'),
//     runTime: Yup.number().integer().positive('Run time must be a positive number.').typeError('Run time must be a number.'),
//     // round: Yup.number().integer().positive('Round must be a positive number.').typeError('Round must be a number.'),
// });
export const AdSpaceValidator = Yup.object().shape({
    category: Yup.string().required('Category is required.'),
    subCategory: Yup.string().required('Subcategory is required.'),
    city: Yup.string().required('City is required.'),
    district: Yup.string().required('District is required.'),
    landmark: Yup.string(),
    price: Yup.number().positive('Price must be a positive number.').typeError('Price must be a number.').required('Price is required.'),
    pricing_unit: Yup.string().required('Pricing unit is required.'),
    listing_id: Yup.string().required('Listing code is required.'),
    listing_title_en: Yup.string().required('Listing title is required.'),
    numberofAds: Yup.number().integer().min(1, 'Number of Ads must be at least 1.').typeError('Number of Ads must be a number.').required('Number of Ads is required.'),
    // main_img_url: Yup.array().of(Yup.string().url('Please enter a valid URL.')).required('Image URL is required.'),
});