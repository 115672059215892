import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ForgotPasswordFormValidator } from "../../presenter/validators/forgot-password.validator";
import { useActions } from '../../presenter';
import  image from "../../assets/home-bg.png";
import mashyLogo from '../../assets/mashy-logo.png';
// import Header from '../header';

const ForgotPassword = () => {
    const { forgotPassword } = useActions();
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(ForgotPasswordFormValidator),
    });

    const onSubmit = async (data) => {
        await forgotPassword(data);
    }

    return (
        <>
        <div className="login-page container-login">
           <div className="login-image-full">
           <div className="login-inputs" style={{margin:'90px auto'}}>
            <div className="header-con">
            <img src={mashyLogo} alt="" width={80} height={45} />
                <div className="header-underline" style={{marginLeft:'90px'}}>
                <h1 className="signUp-header">Forgot Password?</h1>
                <div className="login-line"></div>
                </div>
                </div>
                <div className="reset-password">
                    <p className="subheader ">Enter your email to reset your password.</p>
                </div>
                <form onSubmit={handleSubmit(async (data) => await onSubmit(data))}>
                    <div className="form-group">
                        <label className="form-control-label password-label">Email</label>
                        <div className="pass-group">
                            <input
                                type="email"
                                className="form-control  password-input"
                                placeholder="Enter your email address"
                                {...register("email")}
                            />
                        </div>
                    </div>
                    <p className="invalid-feedback d-block">
                        {errors.email?.message}
                    </p>

                    <div className="" >
                        <button className="btn submit-btnTwo d-flex justify-content-center align-items-center" type="submit">
                            Submit
                        </button>
                    </div>
                </form>
            </div>

           </div>
            </div>
            {/* <div className="container forgot-password-container">
                <h1 className="password-header">Forgot Password?</h1>
                <div className="reset-password">
                    <p className="subheader mb-44">Enter your email to reset your password.</p>
                </div>
                <form onSubmit={handleSubmit(async (data) => await onSubmit(data))}>
                    <div className="form-group">
                        <label className="form-control-label">Email</label>
                        <div className="pass-group">
                            <input
                                type="email"
                                className="form-control form-control-input"
                                placeholder="Enter your email address"
                                {...register("email")}
                            />
                        </div>
                    </div>
                    <p className="invalid-feedback d-block">
                        {errors.email?.message}
                    </p>

                    <div className="" >
                        <button className="btn submit-btnTwo d-flex justify-content-center align-items-center" type="submit">
                            Submit
                        </button>
                    </div>
                </form>
            </div> */}
        </>
    );
};
export default ForgotPassword;