import React from 'react';
import { useActions, useAppState } from '../presenter';
import Header from './header';
import { Link,useNavigate } from 'react-router-dom';
import Footer from './footer/Footer';




const ViewCart = () => {
    const { cartItems } = useAppState();
    const navigate = useNavigate();
    const {  fetchCart, updateCart } = useActions();

    const updateCartItem = async (cartItem, action) => {
        await updateCart({ adId: cartItem._id, quantity: action === 'add' ? cartItem.quantity + 1 : cartItem.quantity - 1 });
        fetchCart();
    };

    const handleRequestRent = async () => {
        try {
            navigate('/checkout')
            // await requestRent();
            // fetchCart();
        } catch (error) {
            console.log('errr', error);
        }
    };

    return (
        <>
            <Header />
            
            <div className="cartView-container">

                <div className={cartItems.length === 0 ? "remove-headers" : 'cart-headers-con'}>
                    <h2 className='cartheader-container' >Cart</h2>

                    
                </div>
                <div className="all-cart-info">
                    <div className="cart-items">
                        {cartItems.length === 0 ? (
                            <div>
                            <p className="empty-cart">YOUR CART IS EMPTY</p>
                            <Link className="cart-link" to="/">
                                    Back to Marketplace
                            </Link>
                            <br></br>
                            <Link className="cart-link" to="/user-requests">
                                    Check your requests
                            </Link>
                            

                            </div>
                        ) : (
                            <div className="cartitems-container">
                                <ul className='cart-items-borders'>
                                    {cartItems.map((item) => (
                                        <li key={item.adsId._id} className="cart-item">
                                            <div className="image-details">
                                            <div className="item-image">
                                                <img className='image-dimensions' src={item.adsId.main_img_url[0]} alt={item.adsId.listing_id} />
                                            </div>
                                            <div className="item-details">
                                                <h4 className='item-name'>{item.adsId.subCategory}</h4>
                                                <p className='item-code'>{item.adsId.listing_id}</p>
                                                <p className='item-price'> {item.adsId.price.$numberDecimal} <b>EGP</b></p>
                                                {/* <p>Number of Months: {item.quantity}</p> */}
                                            </div>
                                            </div>
                                                    {/* todo make button delete item from cart */}
                                            {/* <div className="item-counter">
                                                <div className="counter-container">
                                                    <div className="add-item" style={{ cursor: "pointer" }} onClick={() => { updateCartItem(item, 'delete') }}>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H17C17.2652 13 17.5196 12.8946 17.7071 12.7071C17.8946 12.5196 18 12.2652 18 12C18 11.7348 17.8946 11.4804 17.7071 11.2929C17.5196 11.1054 17.2652 11 17 11H7C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12Z" fill="#374957" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </li>

                                    ))}
                                    <div className="backto-marketplace">
                                    <Link to="/">
                                        <button type="button" className="btn cart-back-btn" >BACK TO MARKETPLACE</button>
                                    </Link>
                                    </div>
                                </ul>
                            </div>
                        )}
                    </div>
                    {cartItems.length > 0 && (
                        <div className="cart-summary">
                            <h2 className='header-summary' >Order Summary </h2>
                            <div className="summary-line"></div>
                            <div className="items-spacing">
                                <span className='bold-word'>Subtotal </span> <span className='light-word'> {calculateTotalPrice(cartItems)}</span>
                            </div>
                            <div className="custom-line"></div>
                            <div className="items-spacing">
                                <span className='bold-word'>Spaces Added</span><span className='light-word'> {cartItems.reduce((total, item) => total + item.quantity, 0)} items</span>
                            </div>
                            <div className="custom-line"></div>
                            <div className="items-spacing">
                                <span className='extra-bold-word'>Total </span><span className='light-word'> {calculateTotalPrice(cartItems)}</span>
                            </div>

                            <button type="button" className="btn btn-submit" onClick={handleRequestRent}>Submit Request</button>
                        </div>
                    )}
                </div>
            </div>
            <Footer/>
        </>
    );
};

// Helper function to calculate the total price of items in the cart
const calculateTotalPrice = (cartItems) => {
    return cartItems.reduce((total, item) => total + item.adsId.price.$numberDecimal * item.quantity, 0);
};

export default ViewCart;
