import L from 'leaflet';

import marker from '../../assets/logoicon.svg';
const IconPerson = new L.Icon({
    iconUrl: marker,
    iconRetinaUrl: marker,
    iconAnchor: [32, 32],
    popupAnchor: [0,0],
    tooltipAnchor:[0,0],
    shadowUrl: null,
    shadowSize: [12,12],
    shadowAnchor: [0,0],
    iconSize: new L.Point(32, 32),
    className: 'leaflet-div-icon'
});

export { IconPerson };