import React from 'react';
import Header from '../header';
import './aboutUs.css'
import { Link } from 'react-router-dom';
import Footer from '../footer/Footer';
import aboutUs from '../../assets/about-us.jpg'
import mission from '../../assets/—Pngtree—target aim archive business goal_4981201.png'
import vision from '../../assets/—Pngtree—vector illustration of business vision_6671384.png'

const AboutUs=()=>{
return(
    <>
        <Header activeMenu={"About us"} />
        <div className="about-us container">
        <div className="header-con-works">
               
               <div className="header-underline-works">
               <h1 className="signUp-header">About Us</h1>
               <div className="login-line" style={{width:'100px'}}></div>
               </div>
        </div>
        <div className="all-about-info d-flex col-md-12">
            <div className="about-pic col-md-4">
            <img src={aboutUs} alt="" width={400} height={300} />
            </div>
            <div className="about-info col-md-7">
            <h3 className='company-details'>company details:</h3>
            <p className='first-text'>Mashy Live is an online marketplace for any and all types of outdoor ad spaces to make the process of offering and renting simpler, faster, and transparent.</p>
            <p className='second-text'>We streamline the process of outdoor advertising with our high organized marketplace, growing everyday!</p>
            <p className='third-text'>We will very soon start offering various flexible payment terms that cater to the urgent needs of advertisers of all sectors and sizes.</p>
            </div>

        </div>
        <div className="mission-vision d-flex col-md-12">
            <div className="mission-con col-md-6 d-flex">
                <div className="mission-img col-md-3">
                <img src={mission} alt="" width={90} height={90} />
                </div>
                <div className="content-mission  col-md-9">
                        <h3 className='mission-header'>Our Mission</h3>
                        <p className='mission-body'>Our mission at Mashy Live is to revolutionize the outdoor advertising industry by providing a transparent, efficient, and user-friendly platform for businesses and ad space owners.</p>
                        <p className='mission-body'>We strive to empower our clients with the ability to reach their target audience effectively and affordably, while also maximizing the potential of ad space owners.</p>
                        
                </div>
            </div>
            <div className="vision-con col-md-6 d-flex">
                <div className="vision-img col-md-3">
                <img src={vision} alt="" width={90} height={90} />
                </div>
                <div className="content-vision  col-md-9">
                        <h3 className='vision-header'>Our Vision</h3>
                        <p className='vision-body'>Our vision is to become the leading online marketplace for outdoor advertising in Egypt and beyond. </p>
                        <p className='vision-body'>We envision a future where outdoor advertising is seamlessly integrated into the marketing strategies of businesses of all sizes.</p>
                        <p className='vision-body'>Through innovation and partnerships, we aim to expand our services, offering diverse and impactful advertising solutions that contribute to the growth and success of our clients.</p>
                </div>
            </div>

        </div>

        </div>

        <Footer/>

    </>
)
}
export default AboutUs;