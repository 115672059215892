import React, { useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { IconPerson } from "../map/Icon";

const MapComponent = ({ details }) => {
  // Using state to store the position
  const [position, setPosition] = useState([30.063406, 31.342075]);

  useEffect(() => {
    console.log("the ad space details", details);

    if (!details.location.geoLocation) {
      setPosition(details.location?.coordinates?.coordinates);
    } else {
      setPosition(details.location?.geoLocation);
    }
    console.log("the position", position);
  }, [details]); // The effect will re-run when the 'details' prop changes

  // Rest of your component code...

  return (
    <div className="map-view-container">
      {details.location.geoLocation.length > 0 && (
        <div className="map-view">
          <MapContainer
            center={position}
            zoom={10}
            style={{ width: "70%", height: "300px" }}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position} icon={IconPerson}>
              <Popup>
                {details.listing_title_en} <br />
                {details.price.$numberDecimal} EGP/monthly
              </Popup>
            </Marker>
          </MapContainer>
        </div>
      )}
    </div>
  );
};
export default MapComponent;
