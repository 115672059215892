/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useActions } from "../../presenter";
import Header from "../header";
import { Link } from "react-router-dom";

const UserRequests = () => {
  const [userRequests, setUserRequests] = useState([]);
  // todo get only user requests if not admin
  const { getAllRequests } = useActions();

  useEffect(() => {
    fetchUserRequests();
  }, []);

  const fetchUserRequests = async () => {
    try {
      // Fetch user requests from the backend API
      const response = await getAllRequests();
      console.log("was ist das response", response);
      setUserRequests(response);
    } catch (error) {
      console.error(error);
    }
  };

  const calculateTotalPrice = (request) => {
    const price = request.price;
    // should be request.quantity
    // const numberofAds = request.ad_id[0].numberofAds ||1;
    return request.numberOfMonths * price;
  };

  return (
    <div>
      <Header />
      <div className="all-reqs-container">
        <h1 className="all-reqs-header">User Requests</h1>
        {userRequests?.length === 0 && <h1> No requests </h1>}
        {userRequests && userRequests?.length > 0 ? (
          <>
            {userRequests.map((userRequest, i) => (
              <div className="request-card" key={i}>
                <div>
                  <h3>User Requests:{userRequest.requests[0].user_details.name}</h3>
                  <p>
                    <strong>Number of Months:</strong> {userRequest.requests[0].numberOfMonths}
                  </p>
                  
                  <p>
                    <strong>Tax Registration Number:</strong>
                    {userRequest.requests[0].taxRegistrationNumber}
                  </p>
                  
                  <p>
                    <strong>Notes:</strong> {userRequest.requests[0].notes}
                  </p>
                </div>

                {userRequest.requests.map((request) => (
                  <div key={request._id} className="request">
                    <div>
                      <h4>Creatives:</h4>
                      {request.creatives.length >0 && request.creatives.map((creative, index) => (
                        <img
                          key={index}
                          src={creative}
                          alt="creative"
                          style={{ maxWidth: "100px" }}
                        />
                      ))}
                    </div>
                    <div>
                      <h4>Items:</h4>
                      {request.items.map((item) => (
                        <div key={item._id} className="item">
                          <p>
                            <strong>Quantity:</strong> {item.quantity}
                          </p>
                          <p>
                            <strong>Status:</strong> {item.status}
                          </p>
                          <p>
                            <strong>Ad Link:</strong> <Link target="_blank" to={`/listing/${item.adsId}`}> View Ad</Link>
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default UserRequests;
