import "regenerator-runtime/runtime";
import { redirect } from "react-router-dom";
import axios from "axios";
import {
    BadRequestError,
    ForbiddenError,
    InternalServerError,
    UnauthenticatedError,
    UnexpectedError,
} from "../errors";
import { apiClient } from "./constants";

export const registerUser = async (data) => {
    try {
        const result = await apiClient.post("v1/auth/register", data);
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status === 400)
                throw new BadRequestError(responseData.message);
            if (err.response?.status === 401)
                throw new UnauthenticatedError(responseData.message);
            if (err.response?.status === 403)
                throw new ForbiddenError(responseData.message);
            if (err.response?.status === 404)
                throw new UnexpectedError(responseData.message);
            if (err.response?.status === 500)
                throw new InternalServerError(responseData.message);
        }
        throw new UnexpectedError();
    }
};

export const login = async (data) => {
    try {
        const result = await apiClient.post('v1/auth/login', data);
        return result.data;
    } catch (err) {
        console.log('err auth', err);
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status === 400)
                throw new BadRequestError(responseData.message);
            if (err.response?.status === 401)
                throw new UnauthenticatedError(responseData.message);
            if (err.response?.status === 403)
                throw new ForbiddenError(responseData.message);
            if (err.response?.status === 404)
                throw new UnexpectedError(responseData.message);
            if (err.response?.status === 500)
                throw new InternalServerError(responseData.message);
        }
        throw new UnexpectedError();
    }
};

export const forgotPassword = async (email) => {
    try {
        await apiClient.post("v1/auth/forgot-password", {
            email,
        });
        // redirect to login
        
    redirect("/login");
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status === 400)
                throw new BadRequestError(responseData.message);
            if (err.response?.status === 401)
                throw new UnauthenticatedError(responseData.message);
            if (err.response?.status === 403)
                throw new ForbiddenError(responseData.message);
            if (err.response?.status === 404)
                throw new UnexpectedError(responseData.message);
            if (err.response?.status === 500)
                throw new InternalServerError(responseData.message);
        }
        throw new UnexpectedError();
    }
};

export const resetPassword = async (data) => {
    try {
        await apiClient.put(`v1/auth/reset-password`, data);
    } catch (err) {
        if (axios.isAxiosError(err)) {
            console.log('err', err);
            const responseData = err.response.data;

            if (err.response?.status === 400)
                throw new BadRequestError(responseData.message);
            if (err.response?.status === 401)
                throw new UnauthenticatedError(responseData.message);
            if (err.response?.status === 403)
                throw new ForbiddenError(responseData.message);
            if (err.response?.status === 404)
                throw new UnexpectedError(responseData.message);
            if (err.response?.status === 500)
                throw new InternalServerError(responseData.message);
        }
        throw new UnexpectedError();
    }
};

export const verifyEmailToken = async (data) => {
    try {
        await apiClient.post(`v1/auth/verify-email`, data);
    } catch (err) {
        if (axios.isAxiosError(err)) {
            console.log('err', err);
            const responseData = err.response.data;

            if (err.response?.status === 400)
                throw new BadRequestError(responseData.message);
            if (err.response?.status === 401)
                throw new UnauthenticatedError(responseData.message);
            if (err.response?.status === 403)
                throw new ForbiddenError(responseData.message);
            if (err.response?.status === 404)
                throw new UnexpectedError(responseData.message);
            if (err.response?.status === 500)
                throw new InternalServerError(responseData.message);
        }
        throw new UnexpectedError();
    }
};