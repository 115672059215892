import React from "react";
import { useForm } from "react-hook-form";
import { useActions, useAppState } from "../../presenter";
import { yupResolver } from "@hookform/resolvers/yup";
import { SignupFormValidator } from "../../presenter/validators/registration.validator";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Header from "../header";
import  image from "../../assets/login-img.png"
import "./register.css";
import mashyLogo from '../../assets/mashy-logo.png';
const Register = () => {

    const { registerUser } = useActions();
    const { loadingBtn } = useAppState();
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(SignupFormValidator),
    });

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        const payload={
            "name":data.firstName + " " + data.lastName,
            "email":data.email,
            "password": data.password
        }
        const result = await registerUser(payload);
        if (result) navigate("/login");
    };

    return (
        <>
            {/* <Header /> */}
            <div className="signUp-page container-signUp">
            <div className='signUp-image-full'>
            <div className="signUp-inputss">
            <div className="header-con">
               <img src={mashyLogo} alt="" width={80} height={45} />
                <div className="header-underline" style={{marginLeft:'130px'}}>
                <h1 className="signUp-header">Sign up</h1>
                <div className="login-line"></div>
                </div>
                </div>
            <form className="signup-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                    <label className="form-control-label email-label">First Name</label>
                    <input
                        {...register("firstName")}
                        type="text"
                        className="form-control email-input"
                        placeholder="Enter your first name"
                    />
                </div>
                <p className="invalid-feedback d-block">
                    {errors.firstName?.message}
                </p>

                <div className="form-group">
                    <label className="form-control-label password-label">Last Name</label>
                    <input
                        {...register("lastName")}
                        type="text"
                        className="form-control password-input"
                        placeholder="Enter your last name"
                    />
                </div>
                <p className="invalid-feedback d-block">
                    {errors.lastName?.message}
                </p>

                <div className="form-group">
                    <label className="form-control-label password-label">Email</label>
                    <input
                        {...register("email")}
                        type="email"
                        className="form-control password-input"
                        placeholder="Enter your email"
                    />
                </div>
                <p className="invalid-feedback d-block">
                    {errors.email?.message}
                </p>

                <div className="form-group">
                    <label className="form-control-label password-label">Phone Number</label>
                    <input
                        {...register("phoneNumber")}
                        type="phone"
                        className="form-control password-input"
                        placeholder="Enter your phone number"
                    />
                </div>
                <p className="invalid-feedback d-block">
                    {errors.phoneNumber?.message}
                </p>

                <div className="form-group">
                    <label className="form-control-label password-label">Password</label>
                    <div className="pass-group" id="passwordInput">
                        <input
                            {...register("password")}
                            className="form-control password-input" placeholder="Enter password" type="password" />
                        {/* <span onClick={onEyeClick} className={`fa toggle-password feather-eye" ${eye ? "fa-eye" : "fa-eye-slash"}`} />
                        <span className="toggle-password feather-eye"></span>
                        <span className="pass-checked"><i className="feather-check"></i></span> */}
                    </div>
                    {/* <div className="password-strength" id="passwordStrength">
                      <span id="poor" />
                      <span id="weak" />
                      <span id="strong" />
                      <span id="heavy" />
                    </div>
                    <div id="passwordInfo" /> */}
                </div>
                <p className="invalid-feedback d-block">
                    {errors.password?.message}
                </p>

                <div className="d-flex align-items-center justify-content-center">
                    <button className="btn btn-start signUp-btn" type="submit" disabled={loadingBtn}
                    >
                        {loadingBtn && <span className="spinner-border spinner-border-sm" style={{marginRight:"16px"}} role="status" aria-hidden="true"></span>}
                        Create Account
                    </button>
                </div>
                <div className="d-flex">
                    <p className="account-status">Already have an account?</p>
                    <Link to="/login">Sign in</Link>
                </div>
            </form>
            </div>
                </div>
            

            </div>
        </>
    );
};

export default Register;