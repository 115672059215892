import "regenerator-runtime/runtime";

// import { toast } from "react-toastify";
import { handleError } from "../errors";

export const getCategories = async (context) => {
    try {
        const result = await context.effects.getCategories();
        return result;
    } catch (err) {
        handleError(err);
    }
};

export const getSubcategoriesFields = async (context) => {
    try {
        const result = await context.effects.getSubcategoriesFields();
        return result;
    } catch (err) {
        handleError(err);
    }
};
export const fetchCount = async (context) =>{
    try{
        const result = await context.effects.getCounts();
        return result;
    } catch (err) {
        handleError(err);
    }
}