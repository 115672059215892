import React, { useState, useEffect } from "react";

import { MapContainer, TileLayer,useMapEvents, Marker, Popup } from "react-leaflet";
import {
  LocationClient,
  SearchPlaceIndexForTextCommand,
} from "@aws-sdk/client-location";
import "leaflet/dist/leaflet.css";
import { withAPIKey } from "@aws/amazon-location-utilities-auth-helper";

import '../../assets/css/search.css';
import { IconPerson } from './Icon';

const SearchMap = ({setAdLocation}) => {
  const position = [30.063406, 31.342075];
  const region = "us-east-1";
  const mapName = "EgyptMap";
  // todo move to environment env
  const apiKey =
    "v1.public.eyJqdGkiOiI0NmJiZDVlOC01M2U1LTQxODgtYTRiYy1jZGE4NzBkMjllM2YifVXenZBvf3IHvCQIgQac72InxiGMbvZgv0Gx24mwk_H_co-EQpga2Xw40Tm75eFlhhiQbo_tAZxyvb7oZYo_lMg3p0wlBoKk7Sdg8CZ6AIM3yOsDntf0eQhsYVO_Tgj-bt_JAyYMXNoLRdIJDeJlvM1Rlodb7Xek5QjIFboYef53XLBWcey3HYI7AkQMcEcSrkuSE2DAt02ZyOW2V-dJ59tHWsV20_RwJ8GohgUOuTAQ6qStbY7BPAFsrjHt07i8GS9jiAbJQgGE95KJj16Fy6dIltf4pbDy6cbg7ZTH6VgzQXFGPK5jj4jvB1tcBzSTHVZBgjFQEG7Vf01GI4HICPM.ZWU0ZWIzMTktMWRhNi00Mzg0LTllMzYtNzlmMDU3MjRmYTkx";

  // State to store the client instance
  const [client, setClient] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [results,setResults] = useState([]);
  const [locationData,setLocationData] = useState([]);
  const [option,setOption] = useState('');
  const [marker,setMarker]=useState(null);
  const [isDropDown,setIsDropDown] = useState(true);
  const [mapFlag,setMapFlag] = useState(false);
  // Geo JSON point
  // Step 2: Handle changes to the input and update the state
  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };
//   const [authHelper,setAuthHelper] = useState(null);

  useEffect(() => {
    // This code will run only once when the component is mounted
    const initializeClient = async () => {
      // Create an authentication helper instance using an API key
      const authHelper = await withAPIKey(apiKey);
      //todo pick location from map as alternative
    //   const map = new Map({
    //     container: "map", // HTML element ID of map element
    //     center: position, // initial map center point
    //     zoom: 10, // initial map zoom
    //     style: 'https://maps.geo.region.amazonaws.com/maps/v0/maps/${mapName}/style-descriptor?key=api-key-id',
    // });

      const newClient = new LocationClient({
        region: "us-east-1", // region containing Amazon Location resource
        ...authHelper.getLocationClientConfig(), // Provides configuration required to make requests to Amazon Location
      });

      setClient(newClient); // Set the client instance in the state
    };

    initializeClient();
  }, []); // The empty dependency array ensures this runs only once

  const  LocationMarker= ()=> {
    const map = useMapEvents({
      click(e) {
        console.log("the map is clicked");
        const { lat, lng } = e.latlng;
        const newMarker = {
          lat,
          lng,
        };
        setAdLocation([newMarker.lat,newMarker.lng]);
        setMarker(newMarker);
      },
      locationfound(e) {
        const { lat, lng } = e.latlng;
        const newMarker = {
          lat,
          lng,
        };
        setMarker(newMarker);
        map.flyTo(e.latlng, map.getZoom())
      },
    })
  
    return marker === null ? null : (
      <Marker  position={[marker.lat, marker.lng]} icon={IconPerson}>
            <Popup>
              Ad coordinates ({marker.lat}, {marker.lng}) 
            </Popup>
          </Marker>
    )
  }
  
  const searchLocation = async (e) => {
    e.preventDefault();
    if (client) {
      try {
        const response = await client.send(
          new SearchPlaceIndexForTextCommand({
            IndexName: "MainIndex", // Place index resource to use            
            BiasPosition: [30.063282, 31.383282], // position to search near
            MaxResults: 10, // number of results to return
            Text: searchValue,
            FilterCountries:["EGY"]
          })
        );
        console.log("search results : ",response);
        if(response.Results){
          setLocationData(response.Results);
          const locations= response.Results.map((item)=>{
            return item.Place.Label;
          })
          setResults(locations);
          console.log("set results",results);
        }
        
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  const handleSelectOption = (selected,index) =>{
    setOption(selected);
    console.log('location',locationData[index].Place.Geometry.Point);
    setAdLocation(locationData[index].Place.Geometry.Point);
    //hide dropdown
    setIsDropDown(false)
  }
  const switchState = (e)=>{
    e.preventDefault();
    const newState = !mapFlag;
    setMapFlag(newState);
  }
  const extractCoordinates=(url)=> {
    const coordRegex = /@([-+\d.]+),([-+\d.]+)|!3d([-+\d.]+)!4d([-+\d.]+)/;
    const match = url.match(coordRegex);
    if (match) {
        const lat = match[3] || match[1];
        const lng = match[4] || match[2];
        console.log("the coordinats",{ latitude: parseFloat(lat), longitude: parseFloat(lng) })
        setAdLocation([parseFloat(lat),parseFloat(lng)]);
    }
    return [];
}
  return (
    <div className="search-bar-container">
      
      <h5 className="h5-label">Search for location by name</h5>
          <input
            type="search"
            placeholder="Search"
            className="create-input inputBox dib"
            value={searchValue}
            onChange={handleInputChange} 
          />
        <button
          type="button"
          className="btn btn-primary dib"
          onClick={searchLocation}
        >
          Search
        </button>
      {results.length>0 && isDropDown && (
          <ul className="suggestions">
            {results.map((result, index) => {
              console.log("the locations",result)
              return(
              <li className={`list-group-item clickable ${result === option ? 'active' : ''}`}
              onClick={() => handleSelectOption(result,index)}
              key={index}>
                 <a> - {result}</a></li>
              )
              })}
          </ul>
      )}
      <h5 className="h5-label"> Choose from Map</h5>
      <button
          type="button"
          className="btn btn-secondary dib"
          onClick={switchState}
        >
         {!mapFlag? `Select from Map`:`Hide Map`}
        </button>
        {mapFlag && (
        <MapContainer center={position}
          zoom={10}
          style={{ width: "100%", height: "300px" }}
        >
          <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LocationMarker />
      </MapContainer>
        )}
        
      <h5 className="h5-label">Paste URL from google maps</h5>
      <input
          type="text"
          className="create-input inputBox dib"
          onBlur={(e) => extractCoordinates(e.target.value)}
        />
      
  
      </div>
     
  );
};

export default SearchMap;

 {/* <Map
        // See https://visgl.github.io/react-map-gl/docs/api-reference/map
        initialViewState={{
          latitude: position[0],
          longitude: position[1],
          zoom: 10,
        }}
        {...authHelper.getMapAuthenticationOptions()}
        style={{ height: "80vh", width: "70vw" }}
        mapStyle={ `https://maps.geo.${region}.amazonaws.com/maps/v0/maps/${mapName}/style-descriptor?key=${apiKey}`}}
      >
        <NavigationControl
          position="bottom-right"
          showZoom
          showCompass={false}
        />
      </Map> */}