/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useActions, useAppState } from '../../presenter';
import Header from '../header';

import "./listingDetails.css";
import Footer from '../footer/Footer';
import MapComponent from './mapComponent';
import ImgCarousel from '../ImgCarousel';
import { SizeIcon,LocationIcon,CodeIcon } from '../AdIcons';


const AdvertisingSpaceDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [advertisingSpace, setAdvertisingSpace] = useState(null);
  const [quantity, setQuantity] = useState(0);
  const { getListing } = useActions();
  const { fetchCart, addToCart, updateCart } = useActions();
  const { user, cartItems } = useAppState();

  const containerStyle = {
    width: '100%',
    height: '300px',
  };

  const center = {
    lat: 40.7128,
    lng: -74.0060,
  };

  useEffect(() => {
    const fetchAdvertisingSpace = async () => {
      try {
        const response = await getListing(id);
        setAdvertisingSpace(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchAdvertisingSpace();
    if(user){
      fetchCart();
    }
  }, [id]);

  useEffect(() => {
    const cartItem = cartItems.find((item) => item._id === id);
      if (cartItem) {
       setQuantity(cartItem.quantity);
      }
  }, [advertisingSpace, cartItems]);

  const handleQuantityChange = async (value) => {
    if (!user) {
      navigate("/login");
      return;
    }
    setQuantity((prevQuantity) => prevQuantity + value);
    const cartItem = cartItems.find(item => item._id === id);
    if (cartItem) {
      await updateCart({ adId: cartItem._id, quantity: cartItem.quantity + value });
    }
    else{
      console.log("the added item",id);
      await addToCart({ adId: id, quantity: value });
    }

    fetchCart();
  };

  if (!advertisingSpace) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Header />
      
     
      <div className="container view-details-con">
      <div className="row">
        <div className="col-md-6">
        <div className="image-con-details">
          <ImgCarousel images={advertisingSpace.main_img_url} />
          {/* <LazyLoadImage className="listing-img" src={advertisingSpace.main_img_url} alt="Advertising Space" /> */}
          </div>
        </div>
        <div className="col-md-6">
        <div className="card details-card">
            <div className="card-body-details">
              <div className="details-title">
              {/* <svg xmlns="http://www.w3.org/2000/svg" style={{marginLeft:'-5px'}} width="25" height="20" viewBox="0 0 13.85 16.859">
                                        <g data-name="Group 414">
                                            <path data-name="Location Icon" d="M13.534 4.844A6.928 6.928 0 1 0 .319 9.01c.934 3.348 6.611 7.849 6.611 7.849s5.669-4.509 6.6-7.849a7.033 7.033 0 0 0 .004-4.166zm-.833 3.9-.008.016v.016c-.631 2.258-3.987 5.459-5.77 6.962-1.775-1.511-5.139-4.7-5.77-6.962v-.031a6.054 6.054 0 0 1-.28-1.823l-.008-.008a6.058 6.058 0 1 1 11.836 1.83z" />
                                            <g data-name="Group 413">
                                                <path data-name="Path 13835" d="M8.745 1.285a6.052 6.052 0 0 0-7.88 5.77l.008.008a6.053 6.053 0 0 0 .28 1.822v.031c.631 2.259 3.995 5.451 5.77 6.962 1.783-1.5 5.139-4.7 5.77-6.962v-.015l.008-.016a6.057 6.057 0 0 0-3.956-7.6zm.023 7.584a2.6 2.6 0 1 1 0-3.676 2.6 2.6 0 0 1 .001 3.677z" />
                                            </g>
                                        </g>
                                    </svg> */}
              <p className="details-title">
                {advertisingSpace.listing_title_en}</p>
                </div>
              {
                                 advertisingSpace.location?.district && 
                                <div className="street-containerr">
                                        <svg  width="16" height="16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="location-dot" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="#333333"  d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"></path></svg>
                                    <span className="street-text">{advertisingSpace.location?.district}</span>
                                </div>
                            }
                            <div className="price">
                            {/* <svg width="25" height="25" style={{marginLeft:'-5px'}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7 16C8 17 10 18 12 18C14.5 18 17 17.5 17 15C17 12.5 14.5495 12 12 12C9.5 12 7 11.5 7 9C7 6.5 9.5 6 12 6C14 6 16 7 17 8M12 3V21" stroke="black" strokeWidth="3"/>
                          </svg> */}
                          {/* <svg width="25" height="25" style={{marginLeft:'-5px'}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18 20C18 20 7.5 20 6 20C6.22318 20 9.5 19 9.5 16C9.5 14.9272 9.5 13.8384 9.5 12M17.1177 7C16.606 5.97596 15.6648 4 13.3029 4C10.941 4 9.5 6 9.5 9C9.5 9.56958 9.5 11.2679 9.5 12M9.5 12L6 12M9.5 12L14 12" stroke="black" strokeWidth="3"/>
                            </svg> */}
                            <p className="price-egp" style={{fontSize:'13px', paddingTop:'5px'}}>EGP</p>
              <p className='details-price'>
              {advertisingSpace.price.$numberDecimal? advertisingSpace.price.$numberDecimal:advertisingSpace.price } {advertisingSpace.currency}
              </p>
                
                </div>
                <hr style={{width:"80%"}}/>
                <p className='details-description'>
                  {advertisingSpace.description}
                </p>
              <div className="">
                {(!user || user.role.toLowerCase() === "user") && !quantity &&
                  <div className="">
                    <button
                      type="button"
                      className="btn details-cart-btn"
                      onClick={() => handleQuantityChange(1)}
                    >
                      Add to cart
                    </button>
                  </div>
                }
                {(!user || user.role.toLowerCase() === "user") && !!quantity && (
                    <div>
                      <button type="button" 
                      onClick={() => handleQuantityChange(-1)}
                      className="btn btn-danger">
                        Remove from Cart
                      </button>
                    </div>
                )}
              </div>
              

              
            </div>
          </div>
        </div>
      </div>
      <div className="row">
                <div className="col-md-6">
                <div className="view-rows-details">
          <h2 className='product-desc'>Ad Space Details:</h2>
          <div className='desc-row'>
            <LocationIcon />
            <p className="label-desc">Location:</p>
            <span className="value-desc">{advertisingSpace.location.district}, {advertisingSpace.location.city}</span>
          </div>

          <div className='desc-row'>
            <SizeIcon/>
            <p className="label-desc">Dimensions:</p>
            <span className="value-desc">
              {advertisingSpace.size?.width.$numberDecimal? advertisingSpace.size?.width.$numberDecimal:advertisingSpace.size?.width} 
              x {advertisingSpace.size?.height.$numberDecimal? advertisingSpace.size?.height.$numberDecimal:advertisingSpace.size?.height} meters</span>
          </div>

          <div className='desc-row'>
            <CodeIcon/>

            <p className="label-desc">Code:</p>
            <span className="value-desc">{advertisingSpace.listing_id}</span>
          </div>
          {advertisingSpace.location?.landmark &&(
            <div className='desc-row'>
            {/* <LandMarkIcon/> */}

            <p className="label-desc">Nearby Landmark:</p>
            <span className="value-desc">{advertisingSpace.location.landmark}</span>
          </div>
          )}

                {/* class Type */}
          {/* <div className='desc-row'>
            <ClassIcon />

            <p className="label-desc">Class Type:</p>
            <span className="value-desc">{advertisingSpace.classType}</span>
          </div> */}

          {advertisingSpace.faces && (
            <div className='desc-row'>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                <g data-name="Group 324" transform="translate(-408 -1465)">
                  <circle data-name="Ellipse 22" cx="15" cy="15" r="15" transform="translate(408 1465)" fill="#588db6" />
                  <g data-name="Group 308">
                    <path data-name="Path 13919" d="M1387.08 240.323h8.842a1.131 1.131 0 1 1 .052 2.261c-.777.008-1.554 0-2.331 0h-6.563c-.007.093.066.122.109.166.275.28.557.553.83.834a1.128 1.128 0 0 1 .041 1.574 1.1 1.1 0 0 1-1.564.083c-.4-.354-.77-.744-1.147-1.123-.634-.636-1.275-1.264-1.891-1.917a1.046 1.046 0 0 1 0-1.493c.975-1.008 1.973-1.995 2.963-2.989a1.289 1.289 0 0 1 .122-.1 1.128 1.128 0 0 1 1.529 1.645c-.327.344-.677.666-1.017 1z" transform="translate(-966.725 1234.614)" fill="#fff" />
                    <path data-name="Path 13920" d="M1385.68 370.049h-8.848a1.13 1.13 0 1 1-.007-2.26h8.84l.032-.049c-.331-.324-.668-.643-.992-.974a1.124 1.124 0 0 1 1.549-1.629c1.026 1 2.037 2.018 3.042 3.04a1.076 1.076 0 0 1 0 1.481q-1.507 1.534-3.04 3.043a1.094 1.094 0 0 1-1.55-.052 1.121 1.121 0 0 1-.036-1.538c.277-.3.574-.578.86-.869a.392.392 0 0 0 .15-.193z" transform="translate(-959.698 1114.85)" fill="#fff" />
                  </g>
                </g>
              </svg>
              <p className="label-desc">Sides:</p>
              <span className="value-desc">{advertisingSpace.faces}</span>
            </div>
          )}
        </div>
                </div>
                <div className="col-md-6">
                <div className=" map-view-details">
                
                <MapComponent details={advertisingSpace} />
              </div>
                </div>

              </div>
      </div>
      <Footer />
    </>
  );
};

export default AdvertisingSpaceDetails;
