import React, { useState,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import statesData from '../../assets/places/governorates.json';
import citiesData from '../../assets/places/cities.json';

const MultiDropDown = ({
  onChange: handleCityFilterChange}) => {
  const [selectedState, setSelectedState] = useState('')
  const [selectedCity, setSelectedCity] = useState('');
  const history=useLocation();

  useEffect(() => {
        if(history.pathname ==='/'){
          setSelectedCity('');
          setSelectedState('');
        }
  }, [history]);
  const findLocation = (id)=>{
      return statesData.data.find(governorate => governorate.id === id);
    
  }
  const handleStateChange = (event) => {
    setSelectedState(event.target.value)
    const location=findLocation(event.target.value)
    handleCityFilterChange(location.governorate_name_en);
    console.log('state/gov change', location)
    setSelectedCity('')
  }

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value)
  }

  const citiesInSelectedState = citiesData.data.filter(
    (city) => city.governorate_id === selectedState
  )

  return (
    <div className="multidd">
      <select
        className="location-dropdown"
        onChange={handleStateChange}
        value={selectedState}
      >
        <option defaultValue="">Select Governorate</option>
        {statesData.data.map((state) => (
          <option key={state.id} value={state.id}>
            {state.governorate_name_en}
          </option>
        ))}
      </select>

      <select
        className="location-dropdown-two"
        // TODO use bootstrap margin top instead
        style={{ marginTop: "10px" }}
        onChange={handleCityChange}
        value={selectedCity}
        disabled={!selectedState}
      >
        <option defaultValue="">Select City</option>
        {citiesInSelectedState.map((city) => (
          <option key={city.id} value={city.id}>
            {city.city_name_en}
          </option>
        ))}
      </select>
    </div>
  )
}

export default MultiDropDown
