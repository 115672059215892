import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AdvertisingSpaces from './components/adSpace/advertisingSpaces';
import AdvertisingSpaceDetails from './components/adSpace/listingDetails';
import Login from './components/auth/login';
import Register from './components/auth/register';
import ForgotPassword from './components/auth/forgotPassword';
import ResetPassword from './components/auth/resetPassword';
import UserRequests from './components/users/userRequests';
import Checkout from './components/users/checkout';
import ViewCart from './components/viewCart';
import ErrorPage from './components/static/errorPage';
import "@fontsource/poppins"; // Defaults to weight 400
import "@fontsource/poppins/400.css"; // Specify weight
import "@fontsource/poppins/400-italic.css"; // Specify weight and style
import CreateAdSpace from './components/admin/adSpace/createAdSpace';
import HowItWorks from './components/howItWorks/HowItWorks';
import RequireAuth from './components/requireAuth';
import NotLoggedIn from './components/notLoggedIn';
import ViewRequests from './components/admin/request/view-request';
// import S3Uploader from './components/admin/adSpace/fileUploader';
import ContactUs from './components/contactUs/ContactUs';
import AboutUs from './components/aboutUs/AboutUs';
import EmailVerified from './components/EmailVerified';


const ROLES = {
  User: "user",
  Admin: "admin",
  Super_Admin: "Super Admin",
};



const App = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="*" element={<ErrorPage status={404} />} />
          <Route path="/" element={<AdvertisingSpaces />} />
          {/* <Route path='/photo' element={<S3Uploader />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          <Route element={<NotLoggedIn />}>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Route>
          <Route path="/verify-email" element={EmailVerified}/>
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/user-requests" element={<UserRequests />} />
          <Route path="/view-cart" element={<ViewCart />} />
          

          <Route
            path="/admin"
            element={
              <RequireAuth allowedRole={[ROLES.Super_Admin, ROLES.Admin]} />
            }>
            <Route path="create-adSpace" element={<CreateAdSpace />} />
            <Route path="requests" element={<UserRequests />} />
          </Route>

          <Route path="/listing/:id" element={<AdvertisingSpaceDetails />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
