import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { yupResolver } from '@hookform/resolvers/yup';
import { useActions, useAppState } from "../../presenter";
import { LoginFormValidator } from "../../presenter/validators/login.validator";
import { Link } from "react-router-dom";
import Header from "../header";
import mashyLogo from '../../assets/mashy-logo.png';
import  image from "../../assets/home-bg.png"
import "./login.css";



const Login = () => {
    const { loadingBtn } = useAppState();
    const { login, fetchCart } = useActions();
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(LoginFormValidator),
    });

    const navigate = useNavigate();

    const onSubmit = (data) => {
        login(data)
            .then(res => {
                if (res)
                    navigate("/");
                    fetchCart();
            });
    }

    return (
        <>
            
                          
            <div className="login-page container-login">
           <div className="login-image-full" >
           <div className="login-inputs" style={{margin:'30px auto'}}>
            <div className="header-con">
            <img src={mashyLogo} alt="" width={80} height={45} />
                <div className="header-underline" style={{marginLeft:'130px'}}>
                <h1 className="signUp-header">Sign in</h1>
                <div className="login-line"></div>
                </div>
                </div>
                <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label className="form-control-label email-label">Email address</label>
                        <input
                            {...register("email")}
                            type="email"
                            className="form-control email-input"
                            placeholder="Enter your email"
                        />
                    </div>
                    <p className="invalid-feedback d-block">
                        {errors.email?.message}
                    </p>

                    <div className="form-group">
                        <label className="form-control-label password-label">Password</label>
                        <div className="pass-group">
                            <input
                                {...register("password")}
                                type="password" name="password" className="form-control password-input" placeholder="Enter your password"
                            />
                           
                        </div>
                    </div>
                    <p className="invalid-feedback d-block">
                        {errors.password?.message}
                    </p>

                    <div className="forgot">
                        <span>
                            <Link className="forgot-link" to="/forgot-password">
                                Forgot password
                            </Link>
                        </span>
                    </div>
                   
                    <div className="d-flex align-items-center justify-content-center">
                        <button className="btn btn-start login-btn" type="submit" disabled={loadingBtn}>
                            {loadingBtn && <span className="spinner-border spinner-border-sm" style={{marginRight:"16px"}} role="status" aria-hidden="true"></span>}
                            Sign in
                        </button>
                    </div>
                    <div className="d-flex">
                        <p className="account-status">Don't have an account?</p>
                        <Link to="/register">Sign up</Link>
                    </div>
                </form>
            </div>
           </div>
               
            </div>
        </>
    );
};
export default Login;