import "regenerator-runtime/runtime";

import { toast } from "react-toastify";
import { handleError } from "../errors";

export const requestRent = async (context,data) => {
    try {
        console.log("calledd");
        context.state.loadingBtn = true;
        const result = await context.effects.requestRent(data);

        toast.success("Request is sent to admins and will be reviewed", {
            position: "top-right",
        });
        context.state.loadingBtn = false;
        return result;
    } catch (err) {
        handleError(err);
        context.state.loadingBtn = false;
    }
};

export const getAllRequests = async (context) => {
    try {
        context.state.loading = true;
        const result = await context.effects.getAllRequests();
        context.state.loading = false;
        return result;
    } catch (err) {
        handleError(err);
        context.state.loading = false;
    }
};

export const acceptOrRejectRequest = async (context, data) => {
    try {
        context.state.loadingBtn = true;
        const result = await context.effects.acceptOrRejectRequest(data);
        context.state.loadingBtn = false;
        return result;
    } catch (err) {
        handleError(err);
        context.state.loadingBtn = false;
    }
};

export const getUserRequests = async (context) => {
    try {
        context.state.loading = true;
        const result = await context.effects.getUserRequests();
        context.state.loading = false;
        return result;
    } catch (err) {
        handleError(err);
        context.state.loadingBtn = false;
    }
};

export const fetchCart = async (context) => {
    try {
        const result = await context.effects.fetchCart();
        context.state.cartItems = result && result?.data ? result.data?.items : [];
        return result;
    } catch (err) {
        handleError(err);
        context.state.loading = false;
    }
};

export const addToCart = async (context, data) => {
    try {
        const result = await context.effects.addToCart(data);
        return result;
    } catch (err) {
        handleError(err);
        context.state.loading = false;
    }
};

export const updateCart = async (context, data) => {
    try {
        const result = await context.effects.updateCart(data);
        return result;
    } catch (err) {
        handleError(err);
        context.state.loading = false;
    }
};

export const removeFromCart = async (context, id) => {
    try {
        const result = await context.effects.removeFromCart(id);
        return result;
    } catch (err) {
        handleError(err);
        context.state.loading = false;
    }
};