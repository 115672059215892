import { toast } from "react-toastify";
import "regenerator-runtime/runtime";

import { handleError } from "../errors";

export const getListings = async (context, data) => {
    try {
        const {filters, subcategories, categories, page,sortBy,sortType,q}= data;
        //todo check if exist
        const url=`v1/ads/adspaces?filters=${filters? filters:''}&q=${q? q:''}&page=${page}${subcategories ? `&subcategories=${data.subcategories}` : ''}${categories ? `&categories=${categories}` : ''}&sortBy=${sortBy}&sortType=${sortType}`
        // pass new existing data
        const result = await context.effects.getListings(url);
        return result;
    } catch (err) {
        handleError(err);
        context.state.loadingBtn = false;
    }
};

export const getListingsByCategory = async (context, data) => {
    try {
        const result = await context.effects.getListingsByCategory(data);
        return result;
    } catch (err) {
        handleError(err);
        context.state.loadingBtn = false;
    }
};

export const getListing = async (context, id) => {
    try {
        const result = await context.effects.getListing(id);
        return result;
    } catch (err) {
        handleError(err);
    }
};

export const createAd = async (context, data) => {
    try {
        console.log("form data sent",data);
        const result = await context.effects.createAd(data);
        toast.success("Ad Space is created successfully", {
            position: "top-right",
        });
        return result;
    } catch (err) {
        handleError(err);
    }
};

export const editAd = async (context, {id, body }) => {
    try {
        const result = await context.effects.editAd(id, body);
        toast.success("Ad Space is updated successfully", {
            position: "top-right",
        });
        return result;
    } catch (err) {
        handleError(err);
    }
};

export const deleteAd = async (context, id) => {
    try {
        const result = await context.effects.deleteAd(id);
        toast.success("Ad Space is deleted successfully", {
            position: "top-right",
        });
        return result;
    } catch (err) {
        handleError(err);
    }
};
