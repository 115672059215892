import React from "react";
import Spinner from 'react-bootstrap/Spinner';
const PageLoader = () => {
  return (
    <>
    <Spinner animation="grow" role="status" variant="primary">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
    </>
  );
};

export default PageLoader;
