import React, { useState } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { useSearchParams,useNavigate } from "react-router-dom";

const SortDropDown = () => {
  // un-comment when feature logic is implemented in the Backend
  // const [sortType, setSortType] = useState("Featured");
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  // todo try utilizing location.pathname
  // const location = useLocation();

  const handleStateChange = (sortBy, sortType) => {
    const currentParams = new URLSearchParams(searchParams.toString());
    currentParams.set("sortBy", sortBy);
    currentParams.set("sortType", sortType);
    setSearchParams(currentParams);
    let cur=window.location.href;
    let arr=cur.split('/');
    let destination=arr[arr.length-1];
    navigate(destination );
  };

  return (
    <div className="sortdd">
      <DropdownButton id="dropdown-basic-button" title={`Sort By`}>
        <Dropdown.Item onClick={() => handleStateChange("updatedAt",-1)}>
          Last Added
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleStateChange("category",1)}>
          Category
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleStateChange("distance",1)}>
          Distance: Nearest
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleStateChange("price",1)}>
          Price: Low to High
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleStateChange("price",-1)}>
          Price: High to Low
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
};

export default SortDropDown;
