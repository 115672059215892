import "regenerator-runtime/runtime";
import axios from "axios";
import {
    BadRequestError,
    ForbiddenError,
    InternalServerError,
    UnauthenticatedError,
    UnexpectedError,
} from "../errors";
import { apiClient } from "./constants";

export const requestRent = async (data) => {
    try {
        console.log("the adta",data);
        const result = await apiClient.post(`v1/ads/checkout`,{...data,userId:JSON.parse(localStorage.getItem("user"))._id});
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status === 400)
                throw new BadRequestError(responseData.error);
            if (err.response?.status === 401)
                throw new UnauthenticatedError(responseData.error);
            if (err.response?.status === 403)
                throw new ForbiddenError(responseData.error);
            if (err.response?.status === 404)
                throw new UnexpectedError(responseData.error);
            if (err.response?.status === 500)
                throw new InternalServerError(responseData.error);
        }
        throw new UnexpectedError();
    }
};

export const getAllRequests = async () => {
    try {
        const result = await apiClient.get("v1/users/allRequests");
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status === 400)
                throw new BadRequestError(responseData.error);
            if (err.response?.status === 401)
                throw new UnauthenticatedError(responseData.error);
            if (err.response?.status === 403)
                throw new ForbiddenError(responseData.error);
            if (err.response?.status === 404)
                throw new UnexpectedError(responseData.error);
            if (err.response?.status === 500)
                throw new InternalServerError(responseData.error);
        }
        throw new UnexpectedError();
    }
};

export const getUserRequests = async () => {
    try {
        const result = await apiClient.get("requests/user-requests");
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status === 400)
                throw new BadRequestError(responseData.error);
            if (err.response?.status === 401)
                throw new UnauthenticatedError(responseData.error);
            if (err.response?.status === 403)
                throw new ForbiddenError(responseData.error);
            if (err.response?.status === 404)
                throw new UnexpectedError(responseData.error);
            if (err.response?.status === 500)
                throw new InternalServerError(responseData.error);
        }
        throw new UnexpectedError();
    }
};

export const fetchCart = async () => {
    try {
        const result = await apiClient.get(`v1/users/viewCart/${JSON.parse(localStorage.getItem("user"))._id}`);
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status === 400)
                throw new BadRequestError(responseData.error);
            if (err.response?.status === 401)
                throw new UnauthenticatedError(responseData.error);
            if (err.response?.status === 403)
                throw new ForbiddenError(responseData.error);
            if (err.response?.status === 404)
                throw new UnexpectedError(responseData.error);
            if (err.response?.status === 500)
                throw new InternalServerError(responseData.error);
        }
        throw new UnexpectedError();
    }
};

export const addToCart = async (data) => {
    try {
        const result = await apiClient.post(`v1/users/addToCart/${JSON.parse(localStorage.getItem("user"))._id}`, data);
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status === 400)
                throw new BadRequestError(responseData.error);
            if (err.response?.status === 401)
                throw new UnauthenticatedError(responseData.error);
            if (err.response?.status === 403)
                throw new ForbiddenError(responseData.error);
            if (err.response?.status === 404)
                throw new UnexpectedError(responseData.error);
            if (err.response?.status === 500)
                throw new InternalServerError(responseData.error);
        }
        throw new UnexpectedError();
    }
};

export const updateCart = async (data) => {
    try {
        const result = await apiClient.put(`v1/users/updateCart/${JSON.parse(localStorage.getItem("user"))._id}`, data);
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status === 400)
                throw new BadRequestError(responseData.error);
            if (err.response?.status === 401)
                throw new UnauthenticatedError(responseData.error);
            if (err.response?.status === 403)
                throw new ForbiddenError(responseData.error);
            if (err.response?.status === 404)
                throw new UnexpectedError(responseData.error);
            if (err.response?.status === 500)
                throw new InternalServerError(responseData.error);
        }
        throw new UnexpectedError();
    }
};

export const removeFromCart = async (id) => {
    try {
        const result = await apiClient.delete(`v1/requests/cart/${id}/remove/`);
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status === 400)
                throw new BadRequestError(responseData.error);
            if (err.response?.status === 401)
                throw new UnauthenticatedError(responseData.error);
            if (err.response?.status === 403)
                throw new ForbiddenError(responseData.error);
            if (err.response?.status === 404)
                throw new UnexpectedError(responseData.error);
            if (err.response?.status === 500)
                throw new InternalServerError(responseData.error);
        }
        throw new UnexpectedError();
    }
};

export const acceptOrRejectRequest = async (data) => {
    try {
        const result = await apiClient.put(`v1/users/acceptOrRejectRequest/${data.id}`, {status: data.status});
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status === 400)
                throw new BadRequestError(responseData.error);
            if (err.response?.status === 401)
                throw new UnauthenticatedError(responseData.error);
            if (err.response?.status === 403)
                throw new ForbiddenError(responseData.error);
            if (err.response?.status === 404)
                throw new UnexpectedError(responseData.error);
            if (err.response?.status === 500)
                throw new InternalServerError(responseData.error);
        }
        throw new UnexpectedError();
    }
};