// import React, { useEffect, useState } from "react";
import React from "react";
import { Link } from "react-router-dom";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { IconPerson } from '../map/Icon';
import { useAppState } from "../../presenter";

const MapView = ({ filteredAdvertisingSpaces }) => {
  // console.log("the ad spaces",filteredAdvertisingSpaces);
  const position = [30.063406, 31.342075];
  const positions = [
    [30.063406, 31.342075],
    [30.064123, 31.343123],
    [30.065321, 31.344321],
    [30.066234, 31.345234],
    [30.067432, 31.446432],
    [30.068345, 31.347345],
    [30.069543, 31.348543],
    [30.073456, 31.349456],
    [30.063654, 31.341654],
    [30.053567, 31.342567],
    [30.043765, 31.343765],
    [30.033678, 31.344678],
    [30.023876, 31.352876],
    [30.013789, 31.353789],
    [30.083987, 31.364987],
    [30.093146, 31.362146],
    [30.063641, 31.373641],
    [30.063247, 31.374247],
    [30.063742, 31.382742],
    [30.063282, 31.383282],
    [30.063828, 31.324828],
  ];
  const { user } = useAppState();
  console.log("current user",user);
  return (
    <div className="map-view-container">
    <div className="map-view">
      <MapContainer
        center={position}
        zoom={12}
        style={{ width: "100%", height: "600px" }}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {filteredAdvertisingSpaces?.map((advertisingSpace,index) => {
         return( 
         <Marker key={index} position={positions[index]} icon={IconPerson}>
            <Popup>
              {advertisingSpace.listing_title_en} <br />
              {advertisingSpace.price.$numberDecimal} EGP/monthly
              <br/>
              <Link  to={`/listing/${advertisingSpace._id}`} >
                view details
                </Link>
            </Popup>
          </Marker>
          )
        })}
      </MapContainer>
    </div>
    </div>
  );
};
export default MapView;
