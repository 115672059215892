import React, { useState } from 'react';

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import { deleteFile } from '../../../presenter/actions/file.actions';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

// Our app
const FilePondUpload=({ children, onUpload })=> {
  const [files, setFiles] = useState([]);
  const [links, setLinks] = useState([]);
  

  const removeParam = (url) => {
    const parsedUrl = new URL(url);
    parsedUrl.search = "";
    const urlWithoutParams = parsedUrl.toString();
    return urlWithoutParams;
  };
  // Configure FilePond
  const pondOptions = {
    server: {
      process: async (fieldName, file, metadata, load, error, progress, abort) => {
        // console.log("the ffile name", file);
        // console.log("metaData", metadata);
        // console.log("what is the fn",fieldName);
        const serverEndpoint = `https://mashy-api.onrender.com/v1/files/s3Url?filename=${file.name}`;
        const { url } = await fetch(serverEndpoint).then(res => res.json())
        console.log("the url",url)
        if (!url) {
          // Presigned URL not available, handle accordingly
          error('Presigned URL not available');
          return;
        }
        file.id=file.name;
        console.log("my named file id", file.id);
        
        // push {file server id:url} in the links state array 
        const tmpObj=removeParam(url);
        setLinks([...links,tmpObj]);
        onUpload([...links,tmpObj])

        const xhr = new XMLHttpRequest();
        xhr.open('PUT', url, true);

        // Handle upload progress
        xhr.upload.onprogress = (e) => {
          progress(e.lengthComputable, e.loaded, e.total);
        };

        // upload file as binary
        xhr.send(file);
        // Handle successful upload
        xhr.onload = function () {
          if (xhr.status >= 200 && xhr.status < 300) {
            // console.log("upload successful",xhr.response )
            // console.log("upload successful",xhr.responseText )
            load(xhr.responseText);
          } else {
            error('Upload failed. Server returned an invalid response.');
          }
        };

        // Handle errors
        xhr.onerror = function () {
          error('Upload failed. Connection error.');
        };
        },
        // nullify revert ?
      revert: (uniqueFileId, load, error)=>{
        
        load();
      }
    },
    headers: {
        'Access-Control-Allow-Origin': '*',
      },
  };
  const beforeRemove= (file)=>{
    // console.log("previous links",links);
    // console.log("is this before2",file.source.id);
    //remove file id from array 
    const deletedObj = links.find(item => file.source.id in item);
    const value = deletedObj ? deletedObj[file.source.id] : undefined;
    if(!undefined){
      deleteFile(value);
      const newArray= links.filter(item => Object.keys(item)[0] !== file.source.id);
      setLinks(newArray);
    }
    // file already deleted 
    // todo error prompt

  }
  const removingFile = (err,file)=>{
    if (err) {
      console.error('Error removing file', err);
    } else {
      console.log("removing file",file);
    }

  }
  return (
    <div className="filePondWrapper">
      <FilePond
        files={files}
        onupdatefiles={setFiles}
        allowMultiple={true}
        maxFiles={3}
        beforeRemoveFile={beforeRemove}
        onremovefile={removingFile}
        server={pondOptions.server}
        name="files" /* sets the file input name, it's filepond by default */
        labelIdle='Drag &amp; Drop your Images or <span class="filepond--label-action">Browse your device</span>'
      />
    </div>
  )
}
export default FilePondUpload;