import axios from 'axios';
import {
    handleError
} from "../errors";

import config from "../../config";
const baseURL= config.appUrl;
export const deleteFile = async (context) => {
    try {
        const response = await axios.delete(`${baseURL}/v1/files/deleteFromS3`, {
            // headers: {
            //   Authorization: authorizationToken
            // },
            data: {
                "fileName": context
            }
        });
    } catch (err) {
        handleError(err);
    }
}