import "regenerator-runtime/runtime";
import axios from "axios";
import {
    BadRequestError,
    ForbiddenError,
    InternalServerError,
    UnauthenticatedError,
    UnexpectedError,
} from "../errors";
import { apiClient } from "./constants";

export const getListings = async (url) => {
    try {
        const result = await apiClient.get(url);
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            console.log("axios error", err);
            const responseData = err?.response?.data;
            console.log("resp error", responseData);

            if (err.response?.status === 400)
                throw new BadRequestError(responseData.error);
            if (err.response?.status === 401)
                throw new UnauthenticatedError(responseData.error);
            if (err.response?.status === 403)
                throw new ForbiddenError(responseData.error);
            if (err.response?.status === 404)
                throw new UnexpectedError(responseData.error);
            if (err.response?.status === 500)
                throw new InternalServerError(responseData.error);
        }
        throw new UnexpectedError();
    }
};

export const getListingsByCategory = async (data) => {
    try {
        const result = await apiClient.get(`v1/ads/adspaces?filters=${data.filters}&category=${data.category}`);
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status === 400)
                throw new BadRequestError(responseData.error);
            if (err.response?.status === 401)
                throw new UnauthenticatedError(responseData.error);
            if (err.response?.status === 403)
                throw new ForbiddenError(responseData.error);
            if (err.response?.status === 404)
                throw new UnexpectedError(responseData.error);
            if (err.response?.status === 500)
                throw new InternalServerError(responseData.error);
        }
        throw new UnexpectedError();
    }
};

export const getListing = async (id) => {
    try {
        const result = await apiClient.get(`v1/ads/adspace/${id}`);
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status === 400)
                throw new BadRequestError(responseData.error);
            if (err.response?.status === 401)
                throw new UnauthenticatedError(responseData.error);
            if (err.response?.status === 403)
                throw new ForbiddenError(responseData.error);
            if (err.response?.status === 404)
                throw new UnexpectedError(responseData.error);
            if (err.response?.status === 500)
                throw new InternalServerError(responseData.error);
        }
        throw new UnexpectedError();
    }
};

export const createListing = async (body) => {
    try {
        const result = await apiClient.post(`v1/ads/adspace`, body);
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status === 400)
                throw new BadRequestError(responseData.error);
            if (err.response?.status === 401)
                throw new UnauthenticatedError(responseData.error);
            if (err.response?.status === 403)
                throw new ForbiddenError(responseData.error);
            if (err.response?.status === 404)
                throw new UnexpectedError(responseData.error);
            if (err.response?.status === 500)
                throw new InternalServerError(responseData.error);
        }
        throw new BadRequestError();
    }
};

export const createAd = async (body) => {
    try {
        const result = await apiClient.post(`v1/ads/adspace`, body);
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status === 400)
                throw new BadRequestError(responseData.error);
            if (err.response?.status === 401)
                throw new UnauthenticatedError(responseData.error);
            if (err.response?.status === 403)
                throw new ForbiddenError(responseData.error);
            if (err.response?.status === 404)
                throw new UnexpectedError(responseData.error);
            if (err.response?.status === 500)
                throw new InternalServerError(responseData.error);
        }
        throw new BadRequestError();
    }
};

export const editAd = async (id, body) => {
    try {
        const result = await apiClient.put(`v1/ads/adspace/${id}`, body);
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status === 400)
                throw new BadRequestError(responseData.error);
            if (err.response?.status === 401)
                throw new UnauthenticatedError(responseData.error);
            if (err.response?.status === 403)
                throw new ForbiddenError(responseData.error);
            if (err.response?.status === 404)
                throw new UnexpectedError(responseData.error);
            if (err.response?.status === 500)
                throw new InternalServerError(responseData.error);
        }
        throw new BadRequestError();
    }
};

export const deleteAd = async (id) => {
    try {
        const result = await apiClient.delete(`v1/ads/adspace/${id}`);
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status === 400)
                throw new BadRequestError(responseData.error);
            if (err.response?.status === 401)
                throw new UnauthenticatedError(responseData.error);
            if (err.response?.status === 403)
                throw new ForbiddenError(responseData.error);
            if (err.response?.status === 404)
                throw new UnexpectedError(responseData.error);
            if (err.response?.status === 500)
                throw new InternalServerError(responseData.error);
        }
        throw new BadRequestError();
    }
};