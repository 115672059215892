import "regenerator-runtime/runtime";
import axios from "axios";
import {
    BadRequestError,
    ForbiddenError,
    InternalServerError,
    UnauthenticatedError,
    UnexpectedError,
} from "../errors";
import { apiClient } from "./constants";

export const getCategories = async (filters) => {
    try {
        const result = await apiClient.get("/v1/ads/categories");
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status === 400)
                throw new BadRequestError(responseData.error);
            if (err.response?.status === 401)
                throw new UnauthenticatedError(responseData.error);
            if (err.response?.status === 403)
                throw new ForbiddenError(responseData.error);
            if (err.response?.status === 404)
                throw new UnexpectedError(responseData.error);
            if (err.response?.status === 500)
                throw new InternalServerError(responseData.error);
        }
        throw new UnexpectedError();
    }
};

export const getSubcategoriesFields = async () => {
    try {
        const result = await apiClient.get("v1/ads/subcategoriesFields");
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status === 400)
                throw new BadRequestError(responseData.error);
            if (err.response?.status === 401)
                throw new UnauthenticatedError(responseData.error);
            if (err.response?.status === 403)
                throw new ForbiddenError(responseData.error);
            if (err.response?.status === 404)
                throw new UnexpectedError(responseData.error);
            if (err.response?.status === 500)
                throw new InternalServerError(responseData.error);
        }
        throw new UnexpectedError();
    }
};

export const getCounts = async () => {
    try {
        const result = await apiClient.get("v1/ads/count-subcategories");
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const responseData = err.response.data;

            if (err.response?.status === 400)
                throw new BadRequestError(responseData.error);
            if (err.response?.status === 401)
                throw new UnauthenticatedError(responseData.error);
            if (err.response?.status === 403)
                throw new ForbiddenError(responseData.error);
            if (err.response?.status === 404)
                throw new UnexpectedError(responseData.error);
            if (err.response?.status === 500)
                throw new InternalServerError(responseData.error);
        }
        throw new UnexpectedError();
    }
};

