import React from 'react';
import './footer.css';
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="unique-color"></div>
        <button type="button" className="btn btn-sendMsg">
          <Link to="/contact-us">Send us a message</Link>
        </button>

        <div className="top-bottom-con">
          <div className="top-elements container d-flex">
            <div
              className="container"
              style={{ maxWidth: "1200px", padding: "0px" }}
            >
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-between">
                  <div className="navigation">
                    <h4 className="footer-headers">NAVIGATION</h4>
                    <ul className="footer-links">
                      <li className="footer-item">
                        <Link aria-current="page" to="/">
                          Marketplace
                        </Link>
                      </li>

                      <li className="footer-item">
                        <Link aria-current="page" to="/admin/requests">
                          Requests
                        </Link>
                      </li>

                      {/* <li className="nav-item">
                            <Link className={"nav-link " + (activeMenu === "" ? "active" : "")} aria-current="page" to="/user-requests">
                                My Requests
                            </Link>
                        </li> */}

                      <li className="footer-item">
                        <Link aria-current="page" to="/contact-us">
                          Contact us
                        </Link>
                      </li>

                      <li className="footer-item">
                        <Link aria-current="page" to="/about-us">
                          About us
                        </Link>
                      </li>

                      <li className="footer-item">
                        <Link
                          aria-current="page"
                          to="https://www.mashy.live/blog"
                        >
                          Blog
                        </Link>
                      </li>

                      {/* <li className="footer-item">
                        <Link aria-current="page" to="/how-it-works">
                          How it works
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="">
                  <div className="ad-spaces">
                    <h4 className="footer-headers">AD SPACES</h4>
                    <ul className="footer-links">
                      <li className="footer-item">
                        <Link aria-current="page" to="/">
                          Billboard
                        </Link>
                      </li>

                      <li className="footer-item">
                        <Link aria-current="page" to="/">
                          Digital Screens
                        </Link>
                      </li>
                      <li className="footer-item">
                        <Link aria-current="page" to="/">
                          Transit Media
                        </Link>
                      </li>
                      <li className="footer-item">
                        <Link aria-current="page" to="/">
                          Place-Based Media
                        </Link>
                      </li>
                      <li className="footer-item">
                        <Link aria-current="page" to="/">
                          Street
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="">
                  <div className="contact">
                    <h4 className="footer-headers">CONTACT</h4>
                    <div className="contact-details">
                      <span className="contact-footer">
                        For all inquiries use{" "}
                        <span className="email-footer">work@mashy.live</span>{" "}
                        or head over to our
                      </span>
                      <Link
                        aria-current="page"
                        to="https://www.mashy.live/#contact"
                      >
                        {" "}
                        contact{" "}
                      </Link>
                      <span className="contact-footer">
                        page and send us a message.
                      </span>
                    </div>
                    <div className="social-links-containerv d-flex">
                      <div className="face-book">
                      <a href="https://www.facebook.com/mashyliveads" target="_blank">
                        <svg
                          width="20"
                          height="24"
                          viewBox="0 0 17 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.1116 10.4645V18H4.58844V10.4645H0V7.40902H4.58844V6.29734C4.58844 2.17012 7.33938 0 13.1599 0C14.9443 0 15.3904 0.179734 16.3676 0.326183V3.34837C15.2736 3.22855 14.9655 3.16198 13.829 3.16198C12.4801 3.16198 11.7579 3.40163 11.0993 3.87426C10.4408 4.34689 10.1116 5.16568 10.1116 6.33728V7.41568H16.3676L14.6894 10.4712H10.1116V10.4645Z"
                            fill="#FCFBFB"
                          />
                        </svg>
                        </a>
                      </div>
                      <div className="instaGram">
                      <a href="https://www.instagram.com/startupswithoutborders/reel/CserYIGP0Zq/" target="_blank">
                        <svg
                          width="24"
                          height="24"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fab"
                          data-icon="instagram"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="#ffffff"
                            d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                          ></path>
                        </svg>
                        </a>
                      </div>
                      <div className="linkedIn">
                      <a href="https://www.linkedin.com/company/mashy-live/" target="_blank">
                        <svg
                          width="24"
                          height="30"
                          viewBox="0 0 18 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.06435 3.70969H0.303162V11.2269H4.06435V3.70969Z"
                            fill="#FCFBFB"
                          ></path>
                          <path
                            d="M14.2388 3.54677C14.1002 3.53591 13.9529 3.53048 13.8055 3.52504C11.6996 3.47073 10.5123 4.25287 10.0963 4.58962C9.98367 4.68196 9.93167 4.73627 9.93167 4.73627V3.73144H6.33514V11.2486H9.93167H10.0963C10.0963 10.4828 10.0963 9.72239 10.0963 8.95655C10.0963 8.54376 10.0963 8.13096 10.0963 7.71817C10.0963 7.20761 10.0357 6.66446 10.443 6.19735C10.7896 5.80628 11.4136 5.61074 12.1156 5.61074C14.1955 5.61074 14.2388 6.78938 14.2388 6.89801C14.2388 6.90344 14.2388 6.90887 14.2388 6.90887V11.2812H18V6.37659C18 4.69825 16.6394 3.70972 14.2388 3.54677Z"
                            fill="#FCFBFB"
                          ></path>
                          <path
                            d="M2.18391 2.73748C3.39006 2.73748 4.36784 2.12467 4.36784 1.36874C4.36784 0.612805 3.39006 0 2.18391 0C0.977769 0 0 0.612805 0 1.36874C0 2.12467 0.977769 2.73748 2.18391 2.73748Z"
                            fill="#FCFBFB"
                          ></path>
                        </svg></a>{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="news">
                    <h4 className="footer-headers">
                      SUBSCRIBE TO OUR NEWSLETTER
                    </h4>
                    <p className="news-content">
                      Subscribe to our newsletter to stay up to date with the
                      latest news
                    </p>
                    <form className="fotter-email-con">
                      <input
                        className="form-control  footer-search"
                        type="search"
                        placeholder="Enter your email adress"
                        aria-label="Search"
                      />
                      <button className="btn footer-email-btn" type="submit">
                        Subscribe
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="line-between-top container"></div>
          <div
            className="bottom-footer container justify-content-between  d-flex"
            style={{ maxWidth: "1200px", padding: "0px" }}
          >
            <div className="copy-right">
              Copyright © 2023. All rights reserved
            </div>
            <div className="terms"> Terms of Use || Privacy Policy </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer