import React from 'react';
import { Carousel } from 'react-bootstrap';
import '../assets/css/imgCarousel.css';
const ImgCarousel = ({ images=[] }) => {
  if (images.length === 0) {
    return <img 
    className="noi"
    src="https://cdn4.iconfinder.com/data/icons/picture-sharing-sites/32/No_Image-1024.png"
    alt ="No image to display"
    />;
  }

  return (
    <Carousel className="img-Carousel-container">
      {images.map((image, index) => (
        <Carousel.Item key={index}>
          <img
            className="carousel-image"
            src={image}
            alt={`Slide ${index + 1}`}
          />
          <Carousel.Caption>
            <h3>Image {index + 1}</h3>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default ImgCarousel;
