import * as Yup from "yup";

export const LoginFormValidator = Yup.object().shape({
    email: Yup.string("Please Enter a valid text")
        .email("Please Enter a valid email")
        .required("Please fill in the required field"),

    password: Yup.string("Please Enter a valid text")
        .required("Please fill in the required field"),
});
