import React, { useEffect, useState } from 'react';
import { useActions } from "../presenter";
import { useLocation,useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

const EmailVerified = () => {
  const [message, setMessage] = useState('');
  const location = useLocation();
  
  const { verifyEmail } = useActions();
  
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    if (token) {
        toast.success("User verified, please sign in to continue", {
            position: "top-right",
        });
      setMessage('User verified, please sign in to continue');
    const fetchData= async ()=>{
        
      const response = await verifyEmail({token});
      if(response){
        navigate("/login");
      }
      else {
        setMessage('Token not found in URL.');
      }
      
    }
    fetchData();
    
    
  }
  }, [location.search]);

  return (
    <div>
      <h2>Message</h2>
      <p>{message}</p>
    </div>
  );
};

export default EmailVerified;
