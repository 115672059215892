import * as Yup from "yup";

export const SignupFormValidator = Yup.object().shape({
    firstName: Yup.string("Please Enter a valid text")
        .required("Please fill in the required field"),

    lastName: Yup.string("Please Enter a valid text")
        .required("Please fill in the required field"),

    email: Yup.string("Please Enter a valid text")
        .email("Please Enter a valid email")
        .required("Please fill in the required field"),

    password: Yup.string("Please Enter a valid text")
        .required("Please fill in the required field"),

    phoneNumber: Yup.number("Please Enter a valid number")
        .typeError('Phone Number must be a number.')
        .required("Please fill in the required field"),
});
