import React, { useEffect, useState } from "react";
import { Link,useLocation } from "react-router-dom";
import { useActions, useAppState } from "../presenter";
import mashyLogo from '../assets/mashy-logo.png';
import { useSearchParams } from 'react-router-dom';
import '../assets/css/header.css'
const Header = ({ activeMenu }) => {
    const { fetchCart, logout } = useActions();
    const { user, cartItems } = useAppState();
    const [totalItems, setTotalItems] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const location=useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        if (user){
            console.log("fetch Cart called");
            fetchCart();
        }
    }, [user]);
    // todo fix bug of not resetting
//     useEffect(() => {
//         if(location.pathname ==='/'){
//             setSearchValue('');
//         }
//   }, [location]);
    useEffect(() => {
        setTotalItems(cartItems.reduce((acc, item) => acc + item.quantity, 0));
    }, [cartItems]);
    const handleSearchChange = (e) => {
        console.log("searching",e.target.value);
        setSearchValue(e.target.value);
      };
    const handleSearchClick = () => {
        const currentParams = new URLSearchParams(searchParams);
        // Append the new parameter
        currentParams.set('q', searchValue);
        console.log("cur params",currentParams.get('q'));
        // Update the search params in the URL
        setSearchParams(currentParams);
      };
    return (
        <div className="mb-tw">
        <div className="first-nav">
        <nav className="navbar navbar-expand-lg nav-color first-nav">
            <div className="container-navigation">
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex align-items-center">
                        <li className="nav-item">
                            <Link to='/'>
                            <img src={mashyLogo} alt="" width={200} height={100} />
                            </Link>
                        </li>
                        <li className="d-flex search-con">
                        <input className="form-control home-search" onBlur={handleSearchChange}
                         type="search" placeholder="Search for Ads" aria-label="Search" />
                        
                        <button className="btn search-btn" onClick={handleSearchClick}>
                            Search
                        </button>    
                        </li>
                        {/* <li>
                        <button className="searchBtn btn" onClick={handleSearchClick}>
                            Search
                        </button>       
                        </li> */}

                    </ul>
                   
                    <Link style={{textDecoration:"none"}} className="market-profile" to="/login">
                       
                      
                    
                    {!user &&
                     <div className="login-user-con d-flex align-items-center">
                      <div className="separator-line"></div>
                      <div className="user-login">Login</div>
                    <svg className="loginTwo" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 19">
                        <path id="Path_13841" data-name="Path 13841" d="M1098,35a5,5,0,1,0-5-5A5,5,0,0,0,1098,35Zm0,1.5c-3.34,0-10,1.675-10,5v1.25a1.252,1.252,0,0,0,1.25,1.25h17.5a1.252,1.252,0,0,0,1.25-1.25V41.5C1108,38.175,1101.34,36.5,1098,36.5Z" transform="translate(-1088 -25)" fill="#174363" fillRule="evenodd"/>
                    </svg>
                    </div>
                    }
                    
                    </Link>

                    {user &&
                    <Link to="/"
                    style={{textDecoration:"none"}}
                    onClick={() => {logout()}}>
                        <div className="logOut-user-con d-flex align-items-center">
                        <div className="separator-line"></div>
                        <div className="user-name">hello !</div>
                        <svg className="loginOne" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                            <g clipPath="url(#clip0_156_221)">
                                <path d="M14.375 20C13.8777 20 13.4008 20.1975 13.0492 20.5492C12.6975 20.9008 12.5 21.3777 12.5 21.875V22.875C12.5 23.7701 12.1444 24.6285 11.5115 25.2615C10.8786 25.8944 10.0201 26.25 9.125 26.25H7.125C6.22989 26.25 5.37145 25.8944 4.73851 25.2615C4.10558 24.6285 3.75 23.7701 3.75 22.875V7.125C3.75 6.22989 4.10558 5.37145 4.73851 4.73851C5.37145 4.10558 6.22989 3.75 7.125 3.75H9.125C10.0201 3.75 10.8786 4.10558 11.5115 4.73851C12.1444 5.37145 12.5 6.22989 12.5 7.125V8.125C12.5 8.62228 12.6975 9.09919 13.0492 9.45083C13.4008 9.80246 13.8777 10 14.375 10C14.8723 10 15.3492 9.80246 15.7008 9.45083C16.0525 9.09919 16.25 8.62228 16.25 8.125V7.125C16.248 5.23594 15.4967 3.42482 14.1609 2.08906C12.8252 0.75329 11.0141 0.00198535 9.125 0L7.125 0C5.23594 0.00198535 3.42482 0.75329 2.08906 2.08906C0.75329 3.42482 0.00198535 5.23594 0 7.125L0 22.875C0.00198535 24.7641 0.75329 26.5752 2.08906 27.9109C3.42482 29.2467 5.23594 29.998 7.125 30H9.125C11.0141 29.998 12.8252 29.2467 14.1609 27.9109C15.4967 26.5752 16.248 24.7641 16.25 22.875V21.875C16.25 21.3777 16.0525 20.9008 15.7008 20.5492C15.3492 20.1975 14.8723 20 14.375 20Z" fill="#174363"/>
                                <path d="M28.2011 11.9054L22.4686 6.17287C22.2944 5.99879 22.0876 5.86071 21.8601 5.76653C21.6326 5.67235 21.3887 5.6239 21.1425 5.62396C20.8962 5.62402 20.6524 5.67258 20.4249 5.76687C20.1974 5.86116 19.9908 5.99933 19.8167 6.1735C19.4651 6.52524 19.2677 7.00224 19.2678 7.49956C19.2678 7.74581 19.3164 7.98964 19.4107 8.21712C19.505 8.4446 19.6431 8.65129 19.8173 8.82537L24.0811 13.0891L8.7498 13.1241C8.25252 13.1241 7.77561 13.3217 7.42398 13.6733C7.07235 14.0249 6.8748 14.5018 6.8748 14.9991C6.8748 15.4964 7.07235 15.9733 7.42398 16.3249C7.77561 16.6766 8.25252 16.8741 8.7498 16.8741L24.1473 16.8391L19.8136 21.1729C19.4618 21.5244 19.2641 22.0014 19.264 22.4987C19.2639 22.996 19.4614 23.473 19.8129 23.8247C20.1645 24.1765 20.6414 24.3742 21.1387 24.3743C21.6361 24.3744 22.1131 24.177 22.4648 23.8254L28.1973 18.0929C29.0168 17.2721 29.4775 16.1598 29.4782 14.9999C29.4789 13.84 29.0196 12.7272 28.2011 11.9054Z" fill="#174363"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_156_221">
                                <rect width="30" height="30" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        </div>
                    </Link>
                    }
                         
                    
                    <Link style={{textDecoration:"none"}} className="market-cart" to="/view-cart">
                        <div className="cart-elements d-flex align-items-center">
                            <div className="separator-line"></div>
                        <div className="cart-text">Cart</div>
                            <div className="cart-counter">
                    <div className="cart-counter d-flex justify-content-center">{totalItems}</div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 21.005 21">
                    <path id="Path_13848" data-name="Path 13848" d="M1282.3,41.8a2.1,2.1,0,1,0,2.1,2.1A2.094,2.094,0,0,0,1282.3,41.8ZM1276,26.05a1.051,1.051,0,0,0,1.05,1.05h1.05l3.78,7.969-1.42,2.562a2.106,2.106,0,0,0,1.84,3.118h11.55a1.05,1.05,0,1,0,0-2.1H1282.3l1.15-2.1h7.83a2.079,2.079,0,0,0,1.83-1.082l3.76-6.814a1.043,1.043,0,0,0-.91-1.554h-15.54l-.7-1.5a1.05,1.05,0,0,0-.95-.6h-1.72A1.051,1.051,0,0,0,1276,26.05Zm16.8,15.75a2.1,2.1,0,1,0,2.1,2.1A2.094,2.094,0,0,0,1292.8,41.8Z" transform="translate(-1276 -25)" fill="#174363" fillRule="evenodd"/>
                    </svg>
                    </div>
                    
                    </div>
                    </Link>
                </div>
            </div>
        </nav>
        </div>
        <div className="second-nav">
        <nav className="navbar navbar-expand-lg nav-color second-nav">
            <div className="container-navigation">
                <div className="collapse navbar-collapse" id="navbarSupportedContentSecond">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex align-items-center">
                       

                        <li className="nav-item">
                            <Link className={"nav-link " + (activeMenu === "Marketplace" ? "active" : "")} aria-current="page" to="/">
                                Marketplace
                            </Link>
                        </li>

                        {user && user?.role?.toLowerCase() === "admin" &&
                            <li className="nav-item">
                                <Link className={"nav-link " + (activeMenu === "createAdSpace" ? "active" : "")} aria-current="page" to="/admin/create-adspace">
                                    Create Ad Space
                                </Link>
                            </li>
                        }

                        {user && user?.role?.toLowerCase() === "admin" &&
                            <li className="nav-item">
                                <Link className={"nav-link " + (activeMenu === "requests" ? "active" : "")} aria-current="page" to="/admin/requests">
                                    Requests
                                </Link>
                            </li>
                        }

                        {/* <li className="nav-item">
                            <Link className={"nav-link " + (activeMenu === "" ? "active" : "")} aria-current="page" to="/user-requests">
                                My Requests
                            </Link>
                        </li> */}

                        <li className="nav-item">
                            <Link className={"nav-link " + (activeMenu === "/contact-us" ? "active" : "")}
                            aria-current="page" to="/contact-us">
                                Contact us
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className={"nav-link " + (activeMenu === "About us" ? "active" : "")}
                            aria-current="page" to="/about-us">
                                About us
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className={"nav-link " + (activeMenu === "Blog" ? "active" : "")}
                            aria-current="page" to="https://www.mashy.live/blog">
                                Blog
                            </Link>
                        </li>

                        {/* <li className="nav-item">
                            <Link className={"nav-link " + (activeMenu === "How it works" ? "active" : "")}
                            aria-current="page" to="/how-it-works">
                                How it works
                            </Link>
                        </li> */}
                    </ul>
                   <div className="non-stop">YOUR ONE-STOP-SHOP FOR ALL OOH ADVERTISING NEEDS</div>
                </div>
            </div>
        </nav>
        </div>
        </div>
    );
};

export default Header;